export function analytics(state = false, action) {
  switch (action.type) {
    case 'TRACK':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
