import _ from 'lodash';

import { createIntl, createIntlCache } from 'react-intl';
import Cookies from 'universal-cookie';
import { convertCurrency } from '../core/utils';

export async function handleBtag(btag = '') {
  const cookies = new Cookies();
  if (btag && btag !== '') {
    // if (btag.indexOf('717493') > -1 && btag.indexOf('-') > -1) {
    //   const btagInfo = _.split(btag, '-');
    //   const result = await getUbId(
    //     btagInfo.splice(1, btagInfo.length, btagInfo).join('-'),
    //   );
    //   btag = `${btagInfo[0]}-${result.ubid}`;
    // }

    cookies.set('btag', btag, {
      secure: true,
      path: '/',
      expires: new Date(new Date().setDate(new Date().getDate() + 14)),
    });

    return btag;
  }
  return null;
}

export function initBtag(paramBtag, defaultBtag, fromCookie = false) {
  return (dispatch, getState, { AutobahnReact }) => {
    const cookies = new Cookies();
    const btag = _.defaultTo(paramBtag, _.defaultTo(defaultBtag, ''));
    let affiliateId = '';
    const media = '';
    let sourceBtag = '';
    if (btag !== '') {
      [sourceBtag] = _.split(btag, '-');
      [affiliateId] = _.split(btag, '_');
      if (!sourceBtag) {
        sourceBtag = btag;
      }
    }

    dispatch({
      type: 'SET_BTAG',
      payload: {
        btag,
        // source: "mysource",
        source: `${sourceBtag}`,
        id: affiliateId,
      },
    });

    if (!fromCookie) {
      cookies.set('btag', btag, {
        secure: true,
        path: '/',
        expires: new Date(new Date().setDate(new Date().getDate() + 14)),
      });
    }

    // let sessionBtag = window.sessionStorage.getItem("btag",btag);

    // if incoming btag is null, check the one from the session
    // let validBtag = _.defaultTo(btag, sessionBtag);
    // window.sessionStorage.setItem("btag",validBtag);
  };
}

// @todo, refactor this a bit
export function fetchAffiliateData(btag) {
  return async (dispatch, getState, { AutobahnReact, intl }) => {
    const licenseType = getState().license.type;
    const { locale } = getState().intl;
    const country = getState().user.IpCountry.toLowerCase();

    let welcomeBonus;

    switch (locale) {
      case 'en-in':
        welcomeBonus = 'in';
        break;
      case 'en-gb':
        welcomeBonus = 'uk';
        break;
      default:
        welcomeBonus = licenseType === 'UK' ? 'uk' : 'mga';
    }

    return new Promise((resolve, reject) => {
      // const intlProvider = new IntlProvider(
      //   { locale: getState().intl.locale, messages: getState().intl.messages },
      //   {}
      // );
      // const intl = useIntl();

      // This is optional but highly recommended
      // since it prevents memory leak
      const cache = createIntlCache();
      const intl = createIntl(
        { locale: getState().intl.locale, messages: getState().intl.messages },
        cache,
      );

      function getFreeRoundGames(arrayList) {
        const isAlt =
          getState().user.IpCountry == 'CA' ||
          getState().user.IpCountry == 'BE';

        let gamesBonus1Array = [];
        _.filter(arrayList, { type: 'freeRound' }).map((freeround, key) => {
          gamesBonus1Array = _.merge(
            gamesBonus1Array,
            isAlt ? freeround.altgames : freeround.games,
          );
        });
        const lastGame = gamesBonus1Array.pop();
        return _.size(gamesBonus1Array) > 0
          ? `${_.join(gamesBonus1Array, ',')} or ${lastGame}`
          : lastGame;
      }

      function getPresentationData(rawData) {
        return {
          totalBonus: intl.formatNumber(
            convertCurrency(rawData.totalBonus, getState().wallet.baseCurrency),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),
          totalFreespins: rawData.totalFreespins,
          percentageBonus1:
            _.sumBy(
              _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
              'percentage',
            ) * 100,
          uptoBonus1: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
                'upto',
              ),
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),
          freespinsBonus1: _.sumBy(
            _.filter(rawData.bonuses.bonus_1, { type: 'freeRound' }),
            'rounds',
          ),
          gamesBonus1: getFreeRoundGames(rawData.bonuses.bonus_1),
          exampleBonus1: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
                'upto',
              ) /
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
                  'percentage',
                ),
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),
          exampleResultBonus1: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
                'upto',
              ) +
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
                  'upto',
                ) /
                  _.sumBy(
                    _.filter(rawData.bonuses.bonus_1, { type: 'standard' }),
                    'percentage',
                  ),
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),

          percentageBonus2:
            _.sumBy(
              _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
              'percentage',
            ) * 100,
          uptoBonus2: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
                'upto',
              ),
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),
          gamesBonus2: getFreeRoundGames(rawData.bonuses.bonus_2),
          freespinsBonus2: _.sumBy(
            _.filter(rawData.bonuses.bonus_2, { type: 'freeRound' }),
            'rounds',
          ),
          exampleBonus2: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
                'upto',
              ) /
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
                  'percentage',
                ),
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),
          exampleResultBonus2: intl.formatNumber(
            convertCurrency(
              parseInt(
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
                  'upto',
                ),
              ) +
                parseInt(
                  _.sumBy(
                    _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
                    'upto',
                  ) /
                    _.sumBy(
                      _.filter(rawData.bonuses.bonus_2, { type: 'standard' }),
                      'percentage',
                    ),
                ),
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),

          percentageBonus3:
            _.sumBy(
              _.filter(rawData.bonuses.bonus_3, { type: 'standard' }),
              'percentage',
            ) * 100,
          freespinsBonus3: _.sumBy(
            _.filter(rawData.bonuses.bonus_3, { type: 'freeRound' }),
            'rounds',
          ),
          gamesBonus3: getFreeRoundGames(rawData.bonuses.bonus_3),

          minDeposit: intl.formatNumber(
            convertCurrency(
              locale === 'en-in' ? 1000 : 20,
              getState().wallet.baseCurrency,
            ),
            {
              style: 'currency',
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            },
          ),
          amountDeposits: _.size(rawData.bonuses),
          bonusWagering: 35,
          freespinsWagering: 35,
        };
      }

      const rawData = {
        in: {
          totalBonus: 20000,
          totalFreespins: 100,
          bonuses: {
            bonus_1: [
              {
                type: 'standard',
                upto: 20000,
                percentage: 1,
                wr: 35,
              },
              {
                type: 'freeRound',
                rounds: 100,
                wr: 40,
                games: ['Starburst'],
                altgames: ['Book of Dead'],
              },
            ],
          },
        },
        uk: {
          totalBonus: 100,
          totalFreespins: 50,
          bonuses: {
            bonus_1: [
              {
                type: 'standard',
                upto: 100,
                percentage: 1,
                wr: 35,
              },
              {
                type: 'freeRound',
                rounds: 50,
                wr: 40,
                games: ['Starburst'],
                altgames: ['Book of Dead'],
              },
            ],
          },
        },
        mga: {
          totalBonus: 300,
          totalFreespins: 200,
          bonuses: {
            bonus_1: [
              {
                type: 'standard',
                upto: 100,
                percentage: 1,
                wr: 35,
              },
              {
                type: 'freeRound',
                rounds: 50,
                wr: 40,
                games: ['Starburst'],
                altgames: ['Book of Dead'],
              },
            ],
            bonus_2: [
              {
                type: 'standard',
                upto: '200',
                percentage: 0.5,
                wr: 35,
              },
              {
                type: 'freeRound',
                rounds: 50,
                wr: 40,
                games: ['Starburst'],
                altgames: ['Book of Dead'],
              },
            ],
            bonus_3: [
              {
                type: 'freeRound',
                rounds: 100,
                upto: '100',
                percentage: 1,
                games: ['Starburst'],
                altgames: ['Book of Dead'],
              },
            ],
          },
        },
      };
      const currency = getState().wallet.baseCurrency;

      dispatch({
        type: 'AFFILIATE_DATA',
        payload: {
          welcomePackage: {
            presentation: getPresentationData(rawData[welcomeBonus]),
            rawData: rawData[welcomeBonus],
          },
        },
      });
      resolve();
    });
  };
}
