export default function live(state = { tables: [], isLoaded: false }, action) {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        ...action.payload,
      };

    case 'LIVE_DATA':
      /*
      let tmp = {tables : {}};
      tmp.tables[action.payload.tableId] = action.payload;
      const merged = _.merge({},state, tmp)
      // This isn't the item we care about - keep it as-is

      return {
        ...state,
        ...merged
      };
*/

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.tableId]: {
            ...state.tables[action.payload.tableId],
            ...action.payload.data,
          },
        },
      };

    default:
      return state;
  }
}
