import isMobile from 'ismobilejs';

export function initDevice(useragent = null) {
  const md = useragent ? isMobile(useragent) : isMobile();
  return async (dispatch, getState, { AutobahnReact }) => {
    dispatch({
      type: 'INIT_DEVICE',
      payload: { isMobile: md.any, mobile: md },
    });
  };
}
