import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { boundMethod } from 'autobind-decorator';
import history from '../../core/history';
import { url } from '../../core/url';
import { openModal } from '../../actions/modal';
import routes from '../../routes/routes';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

@connect(null, (dispatch) => ({
  openModal: (name, params) => dispatch(openModal(name, params)),
}))
@injectIntl
class Link extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string,
    rel: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    // closeAllModals : PropTypes.func
  };

  static defaultProps = {
    onClick: null,
    transition: true,
    ignoreTo: false,
    // closeAllModals: null,
  };

  handleClick = (event) => {
    const {
      to,
      targetHash,
      intl,
      children,
      params,
      transition,
      ignoreTo,
      openModal,
      ...props
    } = this.props;

    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }
    // if (this.props.closeAllModals) {
    //  this.props.closeAllModals()
    // }

    event.preventDefault();
    switch (to) {
      case 'register':
        openModal('REGISTER_MODAL');
        break;
      case 'auth':
        console.log('ignore transition');
        break;

      default:
        if (!routes.hasOwnProperty(to)) {
          console.log(`${to} doesnt exist`);
          return;
        }
        console.log('LINK !transition', !transition);
        if (!_.isUndefined(to) && !ignoreTo) {
          history.push(
            url(to, {
              ...params,
              locale: intl.locale,
              path: routes[to][intl.locale],
            }) + (!_.isUndefined(targetHash) ? `#${targetHash}` : ''),
            { noTransition: !transition },
          );
        }
        break;
    }

    // history.push(this.props.to);
  };

  @boundMethod
  setHref() {
    const { to, targetHash, intl, children, params, ...props } = this.props;
    if (!_.isUndefined(to) && routes[to]) {
      return (
        url(to, {
          ...this.props.params,
          locale: intl.locale,
          path: routes[to][intl.locale],
        }) + (!_.isUndefined(targetHash) ? `#${targetHash}` : '')
      );
    }
    return '';
  }

  render() {
    const {
      to,
      href,
      intl,
      children,
      params,
      className,
      target,
      rel,
      ariaLabel = null,
    } = this.props;

    // {url('about', {locale : intl.locale, path : intl.formatMessage({id : 'router.about'}) }) }

    return (
      <a
        href={href || this.setHref()}
        onClick={
          target === '_blank' ||
          (href && (href.indexOf('mailto') > -1 || href.indexOf('http') > -1))
            ? null
            : this.handleClick
        }
        aria-label={ariaLabel}
        className={className}
        rel={rel}
        target={target}
        data-id={this.props['data-id']}
        data-category={this.props['data-category']}
        data-subcategory={this.props['data-subcategory']}
      >
        {children}
      </a>
    );
    // return <a href={to} {...props} onClick={this.handleClick}>{children}</a>;
  }
}

export default Link;
