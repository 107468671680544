export const version = 'AS-2.50';

/* eslint-disable max-len */
export const port = 8080;
export const host = `localhost:${port}`;

export const locales = [
  'en-gb',
  'en',
  //'de',
  'en-ca',
  'en-nz',
  //'en-in',
  'en-za',
]; // 'en-in', 'en-za'

export const ssrCountries = ['US', 'UK', 'DE', 'CA', 'ZA', 'IN', 'NL', 'NO'];

export const supportEmail = 'support@playfrank.com';

export const cockpit = {
  url: 'https://cockpit.playfrank.com',
  token: 'fe83a3926be954923d9cf2fa845a46',
};

export const api = {
  // API URL to be used in the client-side code
  clientUrl: '',
  // API URL to be used in the server-side code
  serverUrl: `http://localhost:3010`,
};

export const analytics = {
  segment: {
    writeKey: '22xRpauLxNKHknKI7zTKYTymhWvznWcm', // FdG4CgBvNdRPT7hgNd0olLJZsgVfcSC4
  },
};

export const consent = {
  necessary: ['LiveChat', 'Amplitude', 'SendinBlue'],
  analytics: ['Mixpanel', 'Google Analytics'],
  marketing: ['Connextra', 'QuantCast'],
};

export const livechat = {
  license: 3953241,
  locales: {
    default: 57,
    no: 58,
    sv: 59,
    fi: 60,
    de: 95,
  },
  blacklist: [
    'askgamblers',
    'complain',
    'authority',
    'ukgc',
    'mga',
    'exclusion',
    'exclude',
    'kyc',
  ],
};
export const cookiedomain = 'playfrank.com';

export const robots = [
  'pingdom',
  'Googlebot',
  'oBot',
  'bingbot',
  'AhrefsBot',
  'python',
  'YandexBot',
  'SemrushBot',
  'Screaming Frog SEO Spider',
];
