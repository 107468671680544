export default function error(state = {}, action) {
  switch (action.type) {
    case 'UNCAUGHT_ERROR':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
