// polyfills
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

const massageArguments = () => {};
// define a new console
// if (window.console && console) {
//   for (let c in console) {
//     if (typeof console[c] === 'function') {
//       const cx = console[c];
//
//       console[c] = function () {
//         //const betterArgs = massageArguments(arguments);
//         //cx.apply(this, [...betterArgs]);
//       };
//     }
//   }
// }

window.addEventListener('error', (ErrorEvent) => {
  //
});
import main from './client';
//
// const loadScripts = () => {
//   import(/* webpackChunkName: "dc-explanation" */ './client').then((main) =>
//     main.default(),
//   );
// };
//
// const loadMain = () => {
//   const loadScriptsTimer = setTimeout(loadScripts, 5000);
//   const userInteractionEvents = [
//     'mouseover',
//     'keydown',
//     'touchmove',
//     'touchstart',
//   ];
//   userInteractionEvents.forEach(function (event) {
//     window.addEventListener(event, triggerScriptLoader, {
//       passive: true,
//     });
//   });
//
//   function triggerScriptLoader() {
//     loadScripts();
//     clearTimeout(loadScriptsTimer);
//     userInteractionEvents.forEach(function (event) {
//       window.removeEventListener(event, triggerScriptLoader, {
//         passive: true,
//       });
//     });
//   }
// };

// Run the application when both DOM is ready and page content is loaded

if (
  ['complete', 'loaded', 'interactive'].includes(document.readyState) &&
  document.body
) {
  main();
} else {
  document.addEventListener('DOMContentLoaded', main, false);
}

//
// if (!global.Intl) {
//   // You can show loading banner here
//
//   require.ensure(
//     [
//       // Add all large polyfills here
//       'intl',
//       'intl/locale-data/jsonp/en.js',
//       'intl/locale-data/jsonp/de.js',
//       'intl/locale-data/jsonp/nn-NO.js',
//       'intl/locale-data/jsonp/fi.js',
//     ],
//     require => {
//       // and require them here
//       require('intl');
//       require('intl/locale-data/jsonp/en.js');
//       require('intl/locale-data/jsonp/de.js');
//       require('intl/locale-data/jsonp/nn-NO.js');
//       require('intl/locale-data/jsonp/fi.js');
//
//       run();
//     },
//     'polyfills',
//   );
// } else {
//   run();
// }
