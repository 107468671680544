// @flow

import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

function dataIdFromObject(obj) {
  switch (obj.__typename) {
    case 'PromotionResult':
      return obj.slug
        ? `PromotionResult:${obj.slug}`
        : defaultDataIdFromObject(obj);
    default:
      return defaultDataIdFromObject(obj);
  }
}

export default function createCache() {
  // https://www.apollographql.com/docs/react/basics/caching.html#configuration
  return new InMemoryCache({
    dataIdFromObject,
    addTypename: true,
    cacheRedirects: {
      Query: {
        getPromotion: (_, args, { getCacheKey }) =>
          getCacheKey({
            __typename: 'PromotionResult',
            slug: args.slug,
            //locale: args.locale,
          }),
      },
    },
    typePolicies: {
      Query: {
        fields: {
          getPromotion: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'PromotionResult',
                slug: args.slug,
                //locale: args.locale,
              });
            },
          },
        },
      },
      getPromotion: {
        // In most inventory management systems, a single UPC code uniquely
        // identifies any product.
        keyFields: ['slug'],
      },
    },

    // typePolicies: {
    //   Promotion: {
    //     // In most inventory management systems, a single UPC code uniquely
    //     // identifies any product.
    //     keyFields: ["slug"]
    //   }
    // }
  });
}
