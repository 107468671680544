export function router(
  state = {
    activeRoute: {
      name: 'home',
      intlParams: {},
      params: { lobbyType: 'general' },
      state: {},
    },
  },
  action,
) {
  switch (action.type) {
    case 'UPDATE_ROUTE':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_INTL_PARAMS':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
