async function checkCanada() {
  if (window.country === 'CA') {
    const request = await fetch('https://ipinfo.io/json?token=45e4de4542b310');
    const jsonResponse = await request.json();
    if (jsonResponse.region === 'Ontario') {
      window.location.href = '/301.html';
    }
  }
}

checkCanada();
