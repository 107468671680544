export default function notification(state = { messages: [] }, action) {
  switch (action.type) {
    case 'SHOW_NOTIFICATION_SUCCESS':
      return {
        ...state,
        ...action.payload,
      };
    case 'SHOW_NOTIFICATION_ERROR':
      return {
        ...state,
        ...action.payload,
      };
    case 'SHOW_NOTIFICATION_INFO':
      return {
        ...state,
        ...action.payload,
      };
    case 'SHOW_NOTIFICATION_WARNING':
      return {
        ...state,
        ...action.payload,
      };
    case 'DESTROY_NOTIFICATION':
      return { message: null };

    default:
      return state;
  }
}
