import Cookies from 'universal-cookie';
import {LOGOUT_USER} from '../constants/Auth';
import {closeAllModals, openModal} from './modal';
import history from '../core/history';
import {url} from '../core/url';
import {logout} from '../data/model/session';

const cookies = new Cookies();

export function startAuthentication(params = { onSuccess: null }) {
  // @todo, add checks if all done
  // First we need to check if we need to show realitycheck
  return dispatch => dispatch(openModal('AUTH_MODAL', params));
}

export function logUserOut() {
  return (dispatch, getState, { client }) => {
    try {
      logout();
      cookies.remove('isLoggedIn');
      cookies.remove('PlayerID');
      cookies.remove('SessionToken');
      cookies.remove('EncryptedUserID');
      dispatch({
        type: LOGOUT_USER,
        payload: {
          isAuthenticated: false,
          requireAuthentication: false,
        },
      });
      history.push(url('home', { locale: getState().intl.locale }));
      dispatch(closeAllModals());
    } catch (e) {
      window.location.href = '/';
    }
  };
}
