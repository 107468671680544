/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import React from 'react';
import Link from '../components/Link';
import { parseQueryString } from './utils';

export const linkMapping = {
  FAQ: 'faq',
  RewardPoints: 'faq',
  Bonuses: 'faq',
  ResponsibleGaming: 'faq',
  GameHelpPage: {
    route: 'faq',
    params: {
      page: 'GameHelpPage',
    },
  },
  // HelpDepositMethods: {
  //   route: 'faq',
  //   params: {
  //     page: 'helpDeposit'
  //   }
  // },
  Security: 'security',
  HelpDepositMethods: 'payments',
  Terms: 'termsAndConditions',
  BonusPolicy: 'bonusTerms',
  Responsible: 'responsibleGaming',
  HelpCashOutPolicy: {
    route: 'faq',
    params: {
      page: 'cashOutPolicy',
    },
  },
  Privacy: 'privacyPolicy',
  FairPlay: 'fairPlay',
  Cookie: {
    route: 'privacyPolicy',
    targetHash: 'Section16',
  },
  HelpDeposit: {
    route: 'faq',
    params: {
      page: 'helpDeposit',
    },
  },
};

export const transform = (node, index) => {
  // find: \[(.*?)]\((https.*?)\)
  // replace: <a href="$2">$1</a>
  // {node.children[0].data}
  // if (node.type === 'tag' && node.name === 'a') {
  //   return this.ImageOrLink(node);
  // }
  // node.attribs = [];

  if (node.type === 'tag' && node.name === 'img') {
    return (
      <img
        src={`https://cockpit.playfrank.com${node.attribs.src}`}
        height={node.attribs.height}
        width={node.attribs.width}
      />
    );
  }

  if (
    node.attribs &&
    node.attribs.href &&
    node.attribs.href.indexOf('https') > -1
  ) {
    const link = _.get(linkMapping, 'Security', 'faq');
    return node;
  }
  if (node.type === 'text' && node.data.indexOf('Create Account') > -1) {
    return <Link to="register">{node.data}</Link>;
  }
  if (
    node.type === 'text' &&
    (node.data.indexOf('$') > -1 ||
      node.data.indexOf('£') > -1 ||
      node.data.indexOf('€') > -1 ||
      node.data.indexOf('kr') > -1 ||
      node.data.indexOf('AU$') > -1 ||
      node.data.indexOf('C$') > -1)
  ) {
    if (node.data.indexOf('$/£/€/kr/AU$/C$/¥') > -1) {
      // skrill and skrill1
      return <span>$/£/€/kr/AU$/C$/¥/₹</span>;
    }
    if (node.data.indexOf('$/£/€/kr/R$/AU$/C$/¥') > -1) {
      // visa and mastercard
      return <span>$/£/€/kr/R$/AU$/C$/¥/₹</span>;
    }
    if (node.data.indexOf('$/£/€/kr/AU$/¥') > -1) {
      // nettler
      return <span>$/£/€/kr/AU$/¥/₹</span>;
    }
    return <span>{node.data}</span>;
  }

  if (node.type === 'tag' && node.name === 'a') {
    if (
      node.attribs &&
      node.attribs.onclick &&
      node.attribs.onclick.match(/ContactUsPopUp/gm)
    ) {
      return <Link onClick={AsgFW.showContactUs}>{node.children[0].data}</Link>;
    }

    const regex = /([^/].*[a-z][A-Z])\.aspx/gim;
    const res =
      node.attribs && node.attribs.href && regex.exec(node.attribs.href);

    if (
      node.attribs &&
      node.attribs.href &&
      node.attribs.href.indexOf('GameHelpPage.aspx') > -1
    ) {
      const params = new URLSearchParams(`?${node.attribs.href.split('?')[1]}`);
      const GameID = params.get('GameID'); // bar
      const link = _.get(linkMapping, 'GameHelpPage', 'faq');
      return (
        <Link
          to={_.isObject(link) ? link.route : link}
          params={
            _.isObject(link)
              ? { ...link.params, ...{ GameID } }
              : { page: resPopup[1], GameID }
          }
        >
          {node.children[0].data}
        </Link>
      );
    }
    if (
      node.attribs &&
      node.attribs.href &&
      node.attribs.href.indexOf('HelpDepositMethods.aspx') > -1
    ) {
      const link = _.get(linkMapping, 'HelpDepositMethods', 'faq');
      return (
        <Link
          to={_.isObject(link) ? link.route : link}
          params={
            _.isObject(link) ? link.params : { page: 'HelpDepositMethods' }
          }
        >
          {node.children[0].data}
        </Link>
      );
    }
    if (node?.name === 'a' && node?.attribs?.target === '_blank') {
      return (
        <a
          href={node?.attribs.href}
          target={node?.attribs?.target}
          rel="noopener noreferrer"
        >
          {node.children[0].data}
        </a>
      );
    }
    if (
      node.attribs &&
      node.attribs.href &&
      node.attribs.href.indexOf('Security.aspx') > -1
    ) {
      const link = _.get(linkMapping, 'Security', 'faq');
      return (
        <Link
          to={_.isObject(link) ? link.route : link}
          params={_.isObject(link) ? link.params : { page: 'Security' }}
        >
          {node.children[0].data}
        </Link>
      );
    }
    if (
      node.attribs &&
      node.attribs.href &&
      node.attribs.href.match(/OpenPopup/gm)
    ) {
      const regexPopup = /javascript:OpenPopup\('([^/].*[a-z])\.aspx'\)/gm;
      const resPopup =
        node.attribs && node.attribs.href && regexPopup.exec(node.attribs.href);

      const link = _.get(linkMapping, resPopup[1], 'faq');
      return (
        <Link
          to={_.isObject(link) ? link.route : link}
          params={_.isObject(link) ? link.params : { page: resPopup[1] }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.children[0].data}
        </Link>
      );
    }
    if (node.attribs && res) {
      const page = res[1].replace('https://www.PlayFrank.com/', '');
      const queryParams = node.attribs.href && parseQueryString(page);

      const link = _.get(linkMapping, page, 'faq');
      return (
        <Link
          to={_.isObject(link) ? link.route : link}
          params={
            _.isObject(link)
              ? { ...link.params, ...queryParams }
              : { page, ...queryParams }
          }
          targetHash={_.isObject(link) ? link.targetHash : ''}
        >
          {node.children.map((item) => {
            if (item.type === 'tag' && item.name === 'img') {
              if (item.attribs.src.indexOf('.gif') > -1) {
                return <img {...item.attribs} />;
              }
              if (item.attribs.src.indexOf('https://') > -1) {
                return (
                  <img
                    {...item.attribs}
                    srcSet={`https://www.playfrank.com/cdn-cgi/image/width=173,height=346,format=auto,dpr=2,sharpen=1/${item.attribs.src}`}
                  />
                );
              }
              return (
                <img
                  {...item.attribs}
                  srcSet={`https://www.playfrank.com/cdn-cgi/image/width=173,height=346,format=auto,dpr=2,sharpen=1/https://www.playfrank.com/${item.attribs.src}`}
                />
              );
            }
            return item.data;
          })}
        </Link>
      );
    }
    if (
      node.attribs &&
      node.attribs.href &&
      node.attribs.href.indexOf('/info/responsible') > -1
    ) {
      const link = _.get(linkMapping, 'Responsible', 'faq');
      return (
        <Link
          to={_.isObject(link) ? link.route : link}
          params={_.isObject(link) ? link.params : { page: 'Responsible' }}
          style={node.attribs.style}
        >
          {node.children[0].data}
        </Link>
      );
    }
  }
};

export const mapLanguage = (locale, flip = false) => {
  const map = {
    'en-gb': 'eng',
    'en-in': 'eng',
    de: 'ger',
    no: 'nor',
  };
  return map[locale];
};

let intervalLoadClick = null;
export const checkIfFrameworkisLoaded = (callback) => {
  if (!window.AsgFWLoaded) {
    intervalLoadClick = window.setTimeout(() => {
      checkIfFrameworkisLoaded(callback);
    }, 1000);
    document.getElementById('slow-loader').style.visibility = 'visible';
  } else {
    document.getElementById('slow-loader').style.visibility = 'hidden';
    callback();
    intervalLoadClick && window.clearInterval(intervalLoadClick);
  }
};
