export const TRACK_EVENTS = {
  PAGE: 'Page',
  CLICK: 'Click',
  ACTION: 'Action',
  CLIENTERROR: 'ClientError',
};

export const TRACK_CLICK_ACTIONS = {
  REGISTER: 'Register',
  Login: 'Click',
};
