export function device(state = { isMobile: false }, action) {
  switch (action.type) {
    case 'INIT_DEVICE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
