import {EventTypes} from 'redux-segment';
import Cookies from 'universal-cookie';
import {TRACK_EVENTS} from '../constants/Analytics';
import {robots, version} from '../config';

/**
 * Track an analytics action
 * @param title
 * @param properties
 * @returns {function(*, *, {AutobahnReact: *})}
 */

const cookies = new Cookies();

const parseCookie = cookiename => {
  const trafficSource = cookies.get(cookiename);
  const items = {
    utmcsr: '',
    utmcmd: '',
    utmccn: '',
    utmcct: '',
    utmctr: '',
    utmgclid: '',
    utmdclid: '',
  };
  if (trafficSource) {
    const itemsSplit = trafficSource.split('|');
    itemsSplit.map(value => {
      if (value) {
        const pair = value.split('=');
        items[pair[0]] = pair[1];
      }
    });
  }
  return items;
};

const getGroup = user => {
  if (user.roles) {
    switch (true) {
      case user.roles.indexOf('Nurture') > -1:
        return 'Nurture';
      case user.roles.indexOf('High Value') > -1:
        return 'High Value';
      case user.roles.indexOf('VIP') > -1:
        return 'VIP';
      default:
        return 'Default';
    }
  }
  return 'Default';
};

export function reset() {
  return (dispatch, getState) => {
    dispatch({
      type: 'ANALYTICS_RESET',
      payload: {},
      meta: {
        analytics: {
          eventType: EventTypes.reset,
        },
      },
    });
  };
}

export function page(name = null, category = null, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== 'undefined' &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      const initialItems = parseCookie('initialTrafficSource');
      const lastItems = parseCookie('lastTrafficSource');
      const gclid = cookies.get('gclid');
      dispatch({
        type: 'ANALYTICS_PAGE',
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.page,
            eventPayload: {
              // options: { integrations: consentList },
              name,
              category,
              properties: {
                ...properties,
                mediaType: getState().browser.mediaType,
                orientation: getState().browser.orientation,
                version,
                btag: getState().affiliate.btag,
                affiliateId: getState().affiliate.id,
                isAuthenticated: getState().auth.isAuthenticated,
                userSegment: getGroup(getState().user),
                initial_utm_source:
                  initialItems.utmcsr && initialItems.utmcsr !== ''
                    ? initialItems.utmcsr
                    : 'direct',
                initial_utm_medium: initialItems.utmcmd,
                initial_utm_campaign: initialItems.utmccn,
                initial_utm_content: initialItems.utmcct,
                initial_utmctr: initialItems.utmctr,
                initial_utm_term: initialItems.utmctr,
                initial_gclid: initialItems.utmgclid,
                initial_dclid: initialItems.utmdclid,
                utm_source:
                  lastItems.utmcsr && lastItems.utmcsr !== ''
                    ? lastItems.utmcsr
                    : 'direct',
                utm_medium: lastItems.utmcmd,
                utm_campaign: lastItems.utmccn,
                utm_content: lastItems.utmcct,
                utm_term: lastItems.utmctr,
                gclid,
                dclid: lastItems.utmdclid,
              },
            },
          },
        },
      });
    }
  };
}

export function identify(playerid = false, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== 'undefined' &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      const initialItems = parseCookie('initialTrafficSource');
      const lastItems = parseCookie('lastTrafficSource');
      const gclid = cookies.get('gclid');
      dispatch({
        type: 'ANALYTICS_IDENTIFY',
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.identify,
            eventPayload: {
              userId: playerid,
              traits: {
                ...properties,
                Version: version,
                btag: getState().affiliate.btag,
                affiliateId: getState().affiliate.id,
                userSegment: getGroup(getState().user),
                initial_utm_source:
                  initialItems.utmcsr && initialItems.utmcsr !== ''
                    ? initialItems.utmcsr
                    : 'direct',
                initial_utm_medium: initialItems.utmcmd,
                initial_utm_campaign: initialItems.utmccn,
                initial_utm_content: initialItems.utmcct,
                initial_utmctr: initialItems.utmctr,
                initial_gclid: initialItems.utmgclid,
                initial_dclid: initialItems.utmdclid,
                initial_utm_term: initialItems.utmctr,
                utm_source:
                  lastItems.utmcsr && lastItems.utmcsr !== ''
                    ? lastItems.utmcsr
                    : 'direct',
                utm_medium: lastItems.utmcmd,
                utm_campaign: lastItems.utmccn,
                utm_content: lastItems.utmcct,
                utm_term: initialItems.utmctr,
                utmctr: lastItems.utmctr,
                gclid,
                dclid: lastItems.utmdclid,
              },
            },
          },
        },
      });
    }
  };
}

export function trackAction(title, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== 'undefined' &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      const initialItems = parseCookie('initialTrafficSource');
      const lastItems = parseCookie('lastTrafficSource');
      const gclid = cookies.get('gclid');
      dispatch({
        type: 'ANALYTICS_TRACK',
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: `${TRACK_EVENTS.ACTION}: ${title}`,
              properties: {
                ...properties,
                mediaType: getState().browser.mediaType,
                orientation: getState().browser.orientation,
                version,
                btag: getState().affiliate.btag,
                affiliateId: getState().affiliate.id,
                isAuthenticated: getState().auth.isAuthenticated,
                userSegment: getGroup(getState().user),
                initial_utm_source:
                  initialItems.utmcsr && initialItems.utmcsr !== ''
                    ? initialItems.utmcsr
                    : 'direct',
                initial_utm_medium: initialItems.utmcmd,
                initial_utm_campaign: initialItems.utmccn,
                initial_utm_content: initialItems.utmcct,
                initial_utmctr: initialItems.utmctr,
                initial_gclid: initialItems.utmgclid,
                initial_dclid: initialItems.utmdclid,
                initial_utm_term: initialItems.utmctr,
                utm_source:
                  lastItems.utmcsr && lastItems.utmcsr !== ''
                    ? lastItems.utmcsr
                    : 'direct',
                utm_medium: lastItems.utmcmd,
                utm_campaign: lastItems.utmccn,
                utm_content: lastItems.utmcct,
                utm_term: initialItems.utmctr,
                utmctr: lastItems.utmctr,
                gclid,
                dclid: lastItems.utmdclid,
              },
            },
          },
        },
      });
    }
  };
}

export function trackClick(title, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== 'undefined' &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      const initialItems = parseCookie('initialTrafficSource');
      const lastItems = parseCookie('lastTrafficSource');
      const gclid = cookies.get('gclid');
      dispatch({
        type: 'ANALYTICS_CLICK',
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: `${TRACK_EVENTS.CLICK}: ${title}`,
              properties: {
                ...properties,
                mediaType: getState().browser.mediaType,
                orientation: getState().browser.orientation,
                version,
                btag: getState().affiliate.btag,
                affiliateId: getState().affiliate.id,
                isAuthenticated: getState().auth.isAuthenticated,
                userSegment: getGroup(getState().user),
                initial_utm_source:
                  initialItems.utmcsr && initialItems.utmcsr !== ''
                    ? initialItems.utmcsr
                    : 'direct',
                initial_utm_medium: initialItems.utmcmd,
                initial_utm_campaign: initialItems.utmccn,
                initial_utm_content: initialItems.utmcct,
                initial_utmctr: initialItems.utmctr,
                initial_utm_term: initialItems.utmctr,
                initial_gclid: initialItems.utmgclid,
                initial_dclid: initialItems.utmdclid,
                utm_source:
                  lastItems.utmcsr && lastItems.utmcsr !== ''
                    ? lastItems.utmcsr
                    : 'direct',
                utm_medium: lastItems.utmcmd,
                utm_campaign: lastItems.utmccn,
                utm_content: lastItems.utmcct,
                utmctr: lastItems.utmctr,
                utm_term: initialItems.utmctr,
                gclid,
                dclid: lastItems.utmdclid,
              },
            },
          },
        },
      });
    }
  };
}
