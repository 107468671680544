export default function aspire(
  state = {
    isFrameworkLoaded: false,
    isGamesLoaded: false,
    isBannerReady: false,
    engaggedBannerDetails: [],
  },
  action,
) {
  switch (action.type) {
    case 'ASG_FRAMEWORK_LOADED':
      return {
        ...state,
        ...action.payload,
      };
    case 'ASG_GAMES_LOADED':
      return {
        ...state,
        ...action.payload,
      };
    case 'IS_BANNER_READY':
      return {
        ...state,
        isBannerReady: !state.isBannerReady,
      };
    case 'SET_ENGAGGED_BANNER_DETAILS':
      return {
        ...state,
        engaggedBannerDetails: action.payload,
      };

    default:
      return state;
  }
}
