export default function livehelp(state = { isLiveHelpOpen: false }, action) {
  switch (action.type) {
    case 'LIVEHELP_IS_OPEN':
    case 'LIVEHELP_IS_CLOSED':
      return {
        ...state,
        isLiveHelpOpen: action.isLiveHelpOpen,
      };

    default:
      return state;
  }
}
