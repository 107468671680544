import _ from 'lodash';

export default function xperience(
  state = {
    currentXpMissions: _.merge({ isLoading: true }, [
      {
        level: 'base',
        missionProgress: [
          {
            action: '["SET_XP_MISSION_PROGRESS=1"]',
            active: 1,
            bonusRewards: [],
            conditions: '["AMOUNT>0"]',
            display: 1,
            events: '["WalletDebit"]',
            expiredate: '2018-05-01T09:42:38.000Z',
            games: [],
            hasThreshold: 14,
            hits: 0,
            id: 166884,
            insertdate: '2018-05-01T09:42:38.000Z',
            isPushedToClient: 0,
            level: 'gold',
            upgrade: 'large',
            locked: 0,
            name: 'PERSONAL MISSION 3',
            needHits: 20,
            needsThreshold: 0,
            origin: 'server',
            player_xpmission_id: 55622,
            pushDate: null,
            pushToClient: 1,
            rewards: [],
            rounds: '0',
            status: 'active',
            weight: 1,
            xplevel_id: null,
          },
          {
            action: '["SET_XP_MISSION_PROGRESS=1"]',
            active: 1,
            bonusRewards: [],
            conditions: '["AMOUNT>0"]',
            display: 1,
            events: '["WalletDebit"]',
            expiredate: '2018-05-01T09:42:38.000Z',
            games: [],
            hasThreshold: 14,
            hits: 0,
            id: 166884,
            insertdate: '2018-05-01T09:42:38.000Z',
            isPushedToClient: 0,
            level: 'gold',
            upgrade: 'large',
            locked: 0,
            name: 'PERSONAL MISSION 3',
            needHits: 20,
            needsThreshold: 0,
            origin: 'server',
            player_xpmission_id: 55622,
            pushDate: null,
            pushToClient: 1,
            rewards: [],
            rounds: '0',
            status: 'active',
            weight: 2,
            xplevel_id: null,
          },
          {
            action: '["SET_XP_MISSION_PROGRESS=1"]',
            active: 1,
            bonusRewards: [],
            conditions: '["AMOUNT>0"]',
            display: 1,
            events: '["WalletDebit"]',
            expiredate: '2018-05-01T09:42:38.000Z',
            games: [],
            hasThreshold: 14,
            hits: 0,
            id: 166884,
            insertdate: '2018-05-01T09:42:38.000Z',
            isPushedToClient: 0,
            level: 'gold',
            upgrade: 'large',
            locked: 0,
            name: 'PERSONAL MISSION 3',
            needHits: 20,
            needsThreshold: 0,
            origin: 'server',
            player_xpmission_id: 55622,
            pushDate: null,
            pushToClient: 1,
            rewards: [],
            rounds: '0',
            status: 'active',
            weight: 3,
            xplevel_id: null,
          },
        ],
      },
    ]),
    eligableXpMissionGames: [],
    finishedXpMission: { rewards: [] },
  },
  action,
) {
  switch (action.type) {
    case 'PUSH_XP_FINISHED_MISSIONS_DIALOG':
    case 'SET_XP_ALL_MISSIONS_FINISHED':
    case 'SET_XP_MISSION_FINISHED':
    case 'SET_XP_CURRENT_MISSIONS':
    case 'SET_XP_CURRENT_COLLECTIONS':
    case 'SET_XP_COLLECTION_PROGRESS':
    case 'SET_XP_MISSION_PROGRESS':
    case 'SET_XP_LEVEL_FINISHED':
    case 'SET_XP_COLLECTION_FINISHED':
    case 'SET_XP_ELIGABLE_GAMES':
    case 'SET_XP_MISSION_UPGRADES':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
