export function game(state = { isActive: false }, action) {
  switch (action.type) {
    case 'START_GAME':
      return {
        ...state,
        ...action.payload,
      };

    case 'GAME_LOADING':
      return {
        ...state,
        ...action.payload,
      };

    case 'GAME_LOADED':
      return {
        ...state,
        ...action.payload,
      };

    case 'GAME_UNLOAD':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
