export const updateRoute = route => (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_ROUTE',
    payload: {
      previousRoute: getState().router.activeRoute,
      activeRoute: { ...route },
    },
  });
};

export const setIntlParams = params => (dispatch, getState) => {
  dispatch({
    type: 'SET_INTL_PARAMS',
    payload: {
      activeRoute: { ...getState().router.activeRoute, intlParams: params },
    },
  });
};
