import React from 'react';
import { createIntl, createIntlCache, defineMessages } from 'react-intl';
import Cookies from 'universal-cookie';
import { get, isFunction } from '../core/utils';
import { INIT_USER_SESSION, LOGIN_USER } from '../constants/Auth';
import { initWallet, setDefaultCurrency } from './wallet';
import { initLicense } from './license';

import { fetchAffiliateData } from './affiliate';
import { initXperience, subscribeXpListeners } from './xperience';

import { error } from './notification';
import { localeUrl } from '../core/url';
import { getSessionDetails } from '../data/model/session';
import { identify } from './analytics.js';

import generateDailychallengeQuery from './generate-dailychallenge.graphql';

const messages = defineMessages({
  sessionExpired: {
    id: 'message.sessionExpiredGeneral',
    defaultMessage:
      'Your session is expired and you are logged out automatically',
    description: 'Session expired message',
  },
  incompleteMessage: {
    id: 'message.incompleteProfile',
    defaultMessage:
      'Your account data is incomplete. Click on the link below to update your profile.',
    description: 'Incomplete account message',
  },
  labelIncomplete: {
    id: 'label.incompleteProfile',
    defaultMessage: 'Update my account.',
    description: 'Update incomplete account label',
  },
  accountEdit: {
    id: 'router.accountEdit',
    description: 'The route used for the account edit page',
    defaultMessage: 'edit-account',
  },
});

export function loadDetails() {
  return async (dispatch) => {
    const session = getSessionDetails();
    if (!session) {
      dispatch({
        type: INIT_USER_SESSION,
        payload: {
          hasAccount: Boolean(
            process.env.BROWSER && localStorage.getItem('USERNAME'),
          ),
        },
      });
    } else {
      dispatch({
        type: INIT_USER_SESSION,
        payload: {
          ...session,
        },
      });
    }

    return session;
  };
}

// @todo make profileid dynamic
export function initUserSession() {
  return async (dispatch, getState, { AutobahnReact, client }) => {
    return new Promise((resolve, reject) => {
      dispatch(loadDetails()).then((result) => {
        if (!result) {
          const country = window.AsgFW.getCountryName();
          if (country === 'United Kingdom') {
            dispatch(setDefaultCurrency('GBP'));
            dispatch(initLicense('UK'));
            dispatch(fetchAffiliateData());
          }

          dispatch({
            type: 'APP_INITIALIZE_DONE',
            payload: { isLoading: false },
          });
          //document.getElementById('slow-loader').style.visibility = 'hidden';
          window.prerenderReady = true;
        } else {
          const personalDetails = window.AsgFW.getSessionDetails();
          console.log('personalDetails', personalDetails);

          const cookies = new Cookies();
          cookies.set('PlayerID', personalDetails?.PlayerID, {
            maxAge: 60 * 60,
            secure: true,
          });
          cookies.set('SessionToken', personalDetails?.SessionToken, {
            maxAge: 60 * 60,
            secure: true,
          });
          cookies.set('EncryptedUserID', personalDetails?.EncryptedUserID, {
            maxAge: 60 * 60,
            secure: true,
          });

          // dispatch(error('Your total balance of £60.00 will be reinstated within 24 hours. For any questions, please contact support.', { autoHide: false }));
          dispatch(
            identify(
              result?.PlayerID?.slice(3, result?.PlayerID?.length).slice(0, -3),
              personalDetails,
            ),
          );
          // dispatch(
          //   initLicense(result.PlayerRegulationType === 'GB' ? 'UK' : 'MGA'),
          // );
          dispatch(initWallet());

          // dispatch(setRealityCheckTimeout());
          // dispatch(initBtag(result.data.userInfo.AffiliateTag));
          // dispatch(closeModal('AUTH_MODAL'));
          // dispatch(pendingWithdrawsItems());

          // client
          //   .mutate({
          //     mutation: generateDailychallengeQuery,
          //     variables: {
          //       encryptedPlayerID: getState().user.EncryptedUserID,
          //       playerID: getState().user.PlayerID,
          //       viplevelID: getState().wallet.loyalty.CurrentLevelNumber,
          //     },
          //   })
          //   .then((result) => {
          //     dispatch(subscribeXpListeners());
          //     dispatch(initXperience());
          //   });

          // dispatch(initXperience());
          const initialSource = cookies.get('initialTrafficSource');
          const lastSource = cookies.get('lastTrafficSource');

          dispatch({
            type: LOGIN_USER,
            payload: {
              isAuthenticated: true,
            },
          });
          // .then(mmSession => {
          // dispatch(setExchangeRate(mmSession.exchangerate));

          // });
        }

        // @ todo

        // For logged out + logged in

        dispatch({
          type: 'APP_INITIALIZE_DONE',
          payload: { isLoading: false },
        });
        //document.getElementById('slow-loader').style.visibility = 'hidden';
        window.prerenderReady = true;

        resolve(result);
      });
    });
  };
}

export function userIncomplete() {
  return async (dispatch, getState) => {
    const cache = createIntlCache();
    const intl = createIntl(
      { locale: getState().intl.locale, messages: getState().intl.messages },
      cache,
    );
    const gotoURL = localeUrl('accountEdit', intl.locale, {
      section: 'incomplete',
    });
    const incompleteMessage = intl.formatMessage(messages.incompleteMessage);
    const incompleteLabel = intl.formatMessage(messages.labelIncomplete);
    const message = (
      <span>
        {incompleteMessage}

        <a href={gotoURL}>{incompleteLabel}</a>
      </span>
    );

    dispatch(error(message, { autoHide: false }));
  };
}
