/* eslint-disable global-require */
import React from 'react';

// The top-level (parent) route
const routes = {
  path: '/:locale',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      name: 'home',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () => await import(/* webpackChunkName: 'home' */ './home'),
    }, // localization done
    {
      path: '/:lobby(lobby|aula)/:category',
      name: 'lobby',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () => await import(/* webpackChunkName: 'home' */ './home'),
    }, // localization done
    {
      path: '/sitemap',
      name: 'sitemap',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () =>
        await import(/* webpackChunkName: 'sitemap' */ './siteMap'),
    }, // localization done
    {
      path: '/:path/:action?/:slug?',
      name: 'register',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () =>
        await import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/:path',
      name: 'auth',
      load: () => import(/* webpackChunkName: 'auth' */ './auth'),
    }, // localization done
    {
      path: '/:path(limited-promotions|promotionen)/:slug',
      name: 'limitedTimePromotion',
      load: () =>
        import(
          /* webpackChunkName: 'promotion-detail' */ './limited-time-promotion/promotion-detail'
        ),
    },
    /* promotion section */
    {
      path: '/:path(limited-promotions|promotionen)',
      name: 'limitedTimePromotions',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(
          /* webpackChunkName: 'promotion-overview' */ './limited-time-promotion/promotion-overview'
        ),
    },
    {
      path: '/:path(promotions|promotionen)/:slug',
      name: 'promotionDetail',
      load: () =>
        import(
          /* webpackChunkName: 'promotion-detail' */ './promotion/promotion-detail'
        ),
    },
    /* promotion section */
    {
      path: '/:path(promotions|promotionen)',
      name: 'promotion',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(
          /* webpackChunkName: 'promotion-overview' */ './promotion/promotion-overview'
        ),
    },

    // {
    //   path: '/:path',
    //   name: 'deposit',
    //   load: () =>
    //     import(/* webpackChunkName: 'deposit-component' */ './deposit/index'),
    // },
    // {
    //   path: '/:path',
    //   name: 'depositStatus',
    //   load: () =>
    //     import(
    //       /* webpackChunkName: 'deposit-paymentstatus' */ './deposit/status'
    //     ),
    // },
    {
      path: '/:path(about)',
      name: 'about',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    }, // localization done
    {
      path: '/:path',
      name: 'payments',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () =>
        await import(/* webpackChunkName: 'payments' */ './payments'),
    }, // localization done
    // {
    //   path: '/:path',
    //   name: 'xperience',
    //   load: () =>
    //     import(/* webpackChunkName: 'loyalty-club' */ './vipClub'),
    //     //import(/* webpackChunkName: 'xperience-tracks' */ './xperience'),'loyalty-club' */ './vipClub'
    // }, // localization done
    {
      path: '/:path',
      name: 'dailyChallenge',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () =>
        await import(
          /* webpackChunkName: 'daily-challenge' */ './dailyChallenge'
        ),
    }, // localization done
    {
      path: '/:path',
      name: 'vipClub',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: async () =>
        await import(/* webpackChunkName: 'loyalty-club' */ './vipClub'),
    }, // localization done
    {
      path: '/:path(faq)/:page?/:GameID?/:rnd?',
      name: 'faq',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    }, // localization done
    {
      path: '/seo/:slug/:name?',
      name: 'gameSeo',
      load: () => import(/* webpackChunkName: 'gameSeo' */ './gameSeo'),
    },
    {
      path: '/:path/:slug/:name?',
      name: 'game',
      load: () => import(/* webpackChunkName: 'game' */ './game'),
    }, // localization done
    {
      path: '/:path/:slug/:tableId?/:vtId?',
      name: 'liveGame',
      load: () => import(/* webpackChunkName: 'game' */ './game/live-index'),
    }, // localization done
    {
      path: '/:path(responsible-gaming)',
      name: 'responsibleGaming',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    }, // localization done
    // {
    //   path: '/:path',
    //   name: 'kyc',
    //   index: false,
    //   changefreq: 'weekly',
    //   priority: 0.8,
    //   load: () => import(/* webpackChunkName: 'kyc' */ './kyc'),
    // }, // localization done
    // {
    //   path: '/:path/:key?',
    //   name: 'forgotPassword',
    //   load: () =>
    //     import(/* webpackChunkName: 'forgot-password' */ './forgotPassword'),
    // },

    /* legal section */
    {
      path: '/:path(terms-conditions)',
      name: 'termsAndConditions',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/:path(bonus-terms-conditions)',
      name: 'bonusTerms',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/:path(cookie-policy)',
      name: 'cookiePolicy',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/:path(privacy-policy)',
      name: 'privacyPolicy',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/:path(fair-play)',
      name: 'fairPlay',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/security',
      name: 'security',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/:path(cash-out-policy)',
      name: 'cashOutPolicy',
      index: false,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content' */ './aspire-content'),
    },
    {
      path: '/:path(subject-rights)',
      name: 'subjectRights',
      index: true,
      changefreq: 'weekly',
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'aspire-content'  */ './aspire-content'),
    },
    {
      path: '/:path/:testgroup?',
      name: 'landing',
      load: () => import(/* webpackChunkName: 'landing' */ './landing'),
    },
    {
      path: '(.*)',
      name: 'infoCasino',
      load: () => import(/* webpackChunkName: 'seo-page' */ './seo-page.route'),
    },
    {
      path: '(.*)',
      name: 'notFound',
      load: () => import(/* webpackChunkName: 'notFound' */ './notFound'),
    },
    {
      path: '(.*)',
      name: 'notFoundSync',
      action: <div />,
    },
  ],

  action({ next }) {
    // Execute each child route until one of them return the result
    const route = next();

    // Provide default values for title, description etc.
    route.title = `${route.title || ''}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
