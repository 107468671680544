import _ from 'lodash';
import universalCookies from 'universal-cookie';
import { cache } from '../core/cache';
import { openModal } from './modal.js';
// import {Mixtures} from '../core/gameification/mixture';
import { trackAction } from './analytics';
import { startDeposit } from './deposit';
import getDailyChallengesQuery from './get-daily-challenges.graphql';

const cookies = new universalCookies();

// @todo, culture dynamic
export function subscribeXpListeners() {
  return (dispatch, getState, { AutobahnReact }) => {
    let openDeposit = false;
    window.addEventListener(
      'message',
      (event) => {
        // if (event.origin !== "http://example.org:8080") return;

        switch (event.data[1]) {
          case 'freeSpinEnded':
          case 'bonusGameEnded':
          case 'bigWinEnded':
            dispatch(trackAction('NetentExtend Event', event.data));
            if (
              getState().game.isActive &&
              getState().game.gameCode == event.data.gameId
            ) {
              // Mixtures.executeMixtures(
              //   {
              //     gameEvent: event.data.event,
              //     gameId: event.data.gameId,
              //     currency: getState().wallet.baseCurrency,
              //     bonusMoney: getState().wallet.totalBonusWallet,
              //     realMoney: getState().wallet.totalCasinoWallet,
              //   },
              //   event.data.event,
              // );
            }

            break;
          case 'gameError':
            if (event.data[2] === 100) {
              openDeposit = true;
            }
            dispatch(trackAction('Netent Game error', event.data));
            break;

          case 'gameErrorClose':
            if (openDeposit) {
              dispatch(trackAction('Start deposit from Netent', event.data));
              dispatch(startDeposit());
            }
            openDeposit = false;
            break;

          default:
          //console.log('NETENT MESSAGE', event);
        }
      },
      false,
    );
  };
}

export function resetXpMissionFinished() {
  return (dispatch) => {
    dispatch({
      type: 'SET_XP_MISSION_FINISHED',
      payload: {
        xpMissionIsFinished: false,
        finishedXpMission: {},
      },
    });

    dispatch({
      type: 'SET_XP_ALL_MISSIONS_FINISHED',
      payload: {
        xpAllMissionsAreFinished: false,
      },
    });
  };
}

export function activateXpCollection(id) {
  return (dispatch, getState, { AutobahnReact }) => {
    cache.clearAll('activeXpCollection');
    cache.clearAll('xpCollections');
    AutobahnReact('madman')
      .call('com.cms.xperience.setActiveXpCollection', { xpcollection_id: id })
      .then(function (result) {
        dispatch(initXperience());
      });
  };
}

export function setXpMissionsPushed() {
  return async (dispatch, getState, { AutobahnReact }) => {
    // resolve
    return AutobahnReact('madman')
      .call('com.cms.xperience.setXpMissionsPushed')
      .then(function (result) {
        return result;
      });
  };
}

export function initXperience() {
  return async (dispatch, getState, { AutobahnReact, client }) => {
    client
      .watchQuery({
        query: getDailyChallengesQuery,
        pollInterval: 10000,
        fetchPolicy: 'no-cache',
        variables: { uid: getState().user.EncryptedUserID },
      })
      .subscribe(
        (result) => {
          dispatch({
            type: 'SET_XP_CURRENT_MISSIONS',
            payload: {
              currentXpMissions: result.data.getDailyChallenges,
            },
          });
        },
        (err: any) => {
          console.log('Error loading data');
        },
      );
    return;
    // Let's check if we have results we need to push to a mobile Modal
    let pushMissionProgress = [];
    let eligableGames = [];
    // try {
    //   result = await cache.getItem('xpMissions');
    //   AutobahnReact('madman')
    //     .call('com.cms.xperience.getXpMissions')
    //     .then(results => {
    //       cache.setItem('xpMissions', results);
    //     });
    //   // eslint-disable-next-line no-empty
    // } catch (e) {
    let result = await AutobahnReact('madman').call(
      'com.cms.xperience.getXpMissions',
    );
    //   cache.setItem('xpMissions', result);
    // }

    if (getState().device.isMobile) {
      const pushMission = _.filter(result.currentXpMissions, {
        status: 'finished',
        isPushedToClient: 0,
      });

      result.currentXpMissions.map((mission) => {
        pushMissionProgress = [
          ..._.filter(mission.missionProgress, {
            status: 'finished',
            isPushedToClient: 0,
          }),
          ...pushMissionProgress,
        ];
      });

      dispatch({
        type: 'PUSH_XP_FINISHED_MISSIONS_DIALOG',
        payload: {
          finishedAllXpMissions: pushMission,
          finishedXpMission: _.head(pushMissionProgress),
        },
      });

      dispatch({
        type: 'SET_XP_MISSION_FINISHED',
        payload: {
          xpMissionIsFinished: true,
        },
      });

      if (_.size(pushMission) > 0 || _.size(pushMissionProgress) > 0) {
        dispatch(openModal('XPMISSION_GAME_COMPLETE_MODAL'));
      }
    }

    if (result.currentXpMissions.length > 0) {
      result.currentXpMissions[0].missionProgress.map(
        (progress, progress_key) => {
          eligableGames =
            !progress.locked && progress.status !== 'finished'
              ? _.concat(eligableGames, _.map(progress.games, 'slug'))
              : eligableGames;
        },
      );
    }
    dispatch({
      type: 'SET_XP_ELIGABLE_GAMES',
      payload: {
        eligableXpMissionGames: eligableGames,
      },
    });

    dispatch({
      type: 'SET_XP_CURRENT_MISSIONS',
      payload: {
        currentXpMissions: _.merge(result.currentXpMissions, {
          isLoading: false,
        }),
      },
    });

    const mixtures = [];

    // if (result.currentXpMissions.length > 0) {
    //   result.currentXpMissions[0].missionProgress.map(mission => {
    //     mixtures.push(mission);
    //   });
    //   Mixtures.prepareMixtures(mixtures);
    // }
    result = null;
    try {
      result = await cache.getItem('xpCollections');
      AutobahnReact('madman')
        .call('com.cms.xperience.getXpCollections')
        .then((results) => {
          cache.setItem('xpCollections', results);
        });
      // eslint-disable-next-line no-empty
    } catch (e) {
      result = await AutobahnReact('madman').call(
        'com.cms.xperience.getXpCollections',
      );
      cache.setItem('xpCollections', result);
    }

    dispatch({
      type: 'SET_XP_CURRENT_COLLECTIONS',
      payload: {
        availableXpCollections: result.xpCollections,
      },
    });

    result = null;
    try {
      result = await cache.getItem('activeXpCollection');
      AutobahnReact('madman')
        .call('com.cms.xperience.getXpCollectionProgress')
        .then((results) => {
          cache.setItem('activeXpCollection', results);
        });
      // eslint-disable-next-line no-empty
    } catch (e) {
      result = await AutobahnReact('madman').call(
        'com.cms.xperience.getXpCollectionProgress',
      );
      cache.setItem('activeXpCollection', result);
    }

    if (_.has(result, 'activeXpCollection')) {
      dispatch({
        type: 'SET_XP_COLLECTION_PROGRESS',
        payload: {
          activeXpCollection: result.activeXpCollection,
        },
      });
    }

    AutobahnReact('madman')
      .call('com.cms.xperience.getXpMissionUpgrades')
      .then(function (result) {
        dispatch({
          type: 'SET_XP_MISSION_UPGRADES',
          payload: {
            activeXpMissionUpgrades: result.upgrades,
          },
        });
      });
  };
}
