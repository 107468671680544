export const call = url => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = url;
  script.onerror = () => {
    window.analytics.track('Error: Failed to load url', { url });
  };

  document.head.appendChild(script);
};

const registerTrackingListeners = () => {
  if (!window.analytics) {
    return;
  }

  const setListeners  = () => {
    if(window.pixie) {
      window.analytics.on('track', function(event, properties, options) {
        switch(event){
          case 'Action: First Deposit':
            call(
              'https://secure.adnxs.com/px?id=1580169&seg=33344976&t=1',
            );
            break;
          // case 'Action: Register success':
          //
          //     call(
          //       'https://secure.adnxs.com/px?id=1580176&t=1',
          //     );
          //   break;
        }
        console.log('event', event);

      });

      window.analytics.on('page', function(event, properties, options) {
        window.pixie('event', 'PageView');
      });

    }
  }
  if (window.analytics.invoked) {
    console.log('window.analytics.initialized');
    setListeners()
  } else {
    window.analytics.ready(() => {
      setListeners()
    });
  }
};

registerTrackingListeners();
