export function itemsHasErrored(state, action) {
  switch (action.type) {
    case 'ITEMS_FETCH_DATA_SUCCESS':
      return action.hasErrored;
    default:
      return state;
  }
}
export function itemsIsLoading(state = false, action) {
  switch (action.type) {
    case 'ITEMS_IS_LOADING':
      return action.isLoading;
    default:
      return state;
  }
}
// There is an if here to get results on staging for the default category.
export function gamebrowser(
  state = {
    games: [],
    selectedGames: [], // NEW property
    gameData: {
      games: [],
      categories: {
        mainCategories: [],
        defaultMainCategoryIndex: 0,
      },
    },
    isLoading: true,
    isGameBrowserNavigationActive: false,
    selectedCategory: '',
    selectedSubCategory: '',
    featureCategories: [
      'FEATURED',
      'LASTPLAYED',
      'NEWGAMES',
      // 'CHRISTMAS',
      'VIDEOSLOTS',
      'JACKPOTGAMES',
      // 'CLASSICSLOTS',
      // 'TABLEGAMES',
      'LIVEGAMES',
      // 'OTHERGAMES',
    ],
    subCategories: { THEME: [] },
    featureLiveCategories: ['FEATURED', 'ROULETTE', 'BLACKJACK', 'BACCARAT'],
    hasActiveGame: false,
    filter: {
      count: 0,
      vendors: [],
      themes: [],
      eligableForBonus: false,
      variance: [],
    },
    pageIndex: 1,
    showAll: false,
    showNumItems: 20,
    vendors: [],
    liveVendors: [],
    orderBy: 'popularity',
    sortOrder: 'desc',
  },
  action,
) {
  switch (action.type) {
    case 'SET_ASPIRE_GAMEDATA':
      return {
        ...state,
        isLoading: false,
        gameData: {
          games: action.games,
          categories: action.categories,
        },
      };

    case 'SET_SELECTED_GAMES':
      return {
        ...state,
        selectedGames: action.games,
        selectedCategory: action.selectedCategory,
        selectedSubCategory: action.selectedSubCategory,
      };

    case 'GET_GAMES':
      return {
        ...state,
        games: action.games,
      };
    case 'SET_ITEMS_PER_MORE':
      return {
        ...state,
        games: action.itemsPerMore,
      };

    case 'ITEMS_FETCH_DATA_SUCCESS':
      return {
        ...state,
        games: action.items,
      };

    case 'GAMEBROWSER_SET_LOADING_STATUS':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case 'GAMEBROWSER_SHOW_MORE':
      return {
        ...state,
        showNumItems: action.showNumItems,
        pageIndex: action.pageIndex,
      };

    case 'GAMEBROWSER_SHOW_ALL':
      return {
        ...state,
        showAll: action.showAll,
      };

    case 'GAMEBROWSER_TO_TOP_IS_ACTIVE':
      return {
        ...state,
        isGoToTopActive: action.isGoToTopActive,
      };

    case 'GAMEBROWSER_SET_CATEGORY':
      return {
        ...state,
        selectedCategory: action.category,
      };
    case 'GAMEBROWSER_SET_SUBCATEGORIES':
      return {
        ...state,
        subCategories: action.subCategories,
      };

    case 'GAMEBROWSER_SET_FILTER_ADD_VARIANCE':
      const idAlreadyExists =
        state.filter.variance.indexOf(action.variance) > -1;

      if (idAlreadyExists) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          variance: [...state.filter.variance, action.variance],
        },
      };

    case 'GAMEBROWSER_SET_FILTER_REMOVE_VARIANCE':
      const index = state.filter.variance.indexOf(action.variance);
      const variance = state.filter.variance
        .slice(0, index)
        .concat(state.filter.variance.slice(index + 1));

      return {
        ...state,
        filter: { ...state.filter, variance },
      };

    case 'GAMEBROWSER_SET_FILTER':
      return {
        ...state,
        filter: { ...state.filter, ...action.filter },
      };
    case 'GAMEBROWSER_SET_FILTER_COUNT':
      return {
        ...state,
        filter: { ...state.filter, count: action.count },
      };
    case 'SET_GAMEVENDORS':
      return {
        ...state,
        vendors: action.vendors,
      };

    case 'SET_LIVEVENDORS':
      return {
        ...state,
        liveVendors: action.liveVendors,
      };

    case 'SET_ORDER_BY':
      return {
        ...state,
        orderBy: action.orderBy,
      };

    case 'SET_SORT_ORDER':
      return {
        ...state,
        sortOrder: action.sortOrder,
      };

    default:
      return state;
  }
}
