/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_WEBAPI_CONNECTING = 'SET_WEBAPI_CONNECTING';

export const SET_WEBAPI_MADMAN_CONNECTED = 'SET_WEBAPI_MADMAN_CONNECTED';
export const SET_WEBAPI_EVERYMATRIX_CONNECTED =
  'SET_WEBAPI_EVERYMATRIX_CONNECTED';
export const SET_WEBAPI_CONNECTING_ERROR = 'SET_WEBAPI_CONNECTING_ERROR';
