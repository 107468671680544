import React, {Children} from 'react';
import PropTypes from 'prop-types';
import Provider from './Provide';
import {Provider as ReduxProvider} from 'react-redux';
import {ApolloProvider} from '@apollo/client';
// import ReactWastageMonitor from 'react-wastage-monitor'

// if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
//   ReactWastageMonitor(React, ReactDOM,{exclude: [
//     /Connect\([^\)]*\)/,
//     /WithStyles\([^\)]*\)/,
//     /InjectIntl\([^\)]*\)/,
//   ]})
// }

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
  // Apollo Client
  client: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  storeSubscription: PropTypes.object,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  render_new() {
    // Here, we are at universe level, sure? ;-)
    const { client } = this.props.context;
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return (
      <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
    );
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    const store = this.props.context && this.props.context.store;
    const state = store && store.getState();
    this.intl = (state && state.intl) || {};
    const { initialNow, locale, messages } = this.intl;
    const localeMessages = (messages && messages[locale]) || {};
    const { client } = this.props.context;
    return (
      <ApolloProvider client={client}><Provider
        initialNow={initialNow}
        locale={locale}
        messages={localeMessages}
        defaultLocale="en"
        store={store}
      >
        {Children.only(this.props.children)}
      </Provider></ApolloProvider>
    );
  }
}

export default App;
