import generateUrls from 'universal-router/generateUrls';
import router from '../router';
import routes from '../routes/routes';

export const url = generateUrls(router);

export const localeUrl = (to, locale, params) => {
  if (!(to in routes)) {
    console.log('Route does not exist in route definition');
    throw new Error('Route does not exist in route definition');
  }
  return url(to, { locale, path: routes[to][locale], ...params });
};
