import _ from 'lodash';

export function modal(state = [], action) {
  switch (action.type) {
    /*
    case 'SHOW_MODAL':
      return {
        modalName: action.payload.modalName,
        modalProps: action.payload.modalProps,
        isOpen: true
      }
*/

    case 'SHOW_MODAL':
      const idAlreadyExists = _.find(state, {
        modalName: action.payload.modalName,
      });

      // make a copy of the existing array
      // let chosenIds = state.chosenIds.slice();

      if (idAlreadyExists) {
        return {
          ...state,
        };
      }
      return [
        {
          modalName: action.payload.modalName,
          modalProps: action.payload.modalProps,
          isOpen: true,
        },
        ...state,
      ];

    case 'HIDE_ALL_MODALS':
      // return list.filter((item) => item.name !== n)
      return [];

    case 'HIDE_MODAL':
      const index = state
        .map(x => x.modalName)
        .indexOf(action.payload.modalName);
      return state.slice(0, index).concat(state.slice(index + 1));

    default:
      return state;
    /*
      //return list.filter((item) => item.name !== n)
      return initialState
    default:
      return state
*/
  }
}
