export default function navigation(
  state = { isNavigationOpen: false, isGameBrowserActive: false },
  action,
) {
  switch (action.type) {
    case 'NAVIGATION_IS_OPEN':
    case 'NAVIGATION_IS_CLOSED':
      return {
        ...state,
        isNavigationOpen: action.isNavigationOpen,
      };

    case 'GAMEBROWSER_IS_ACTIVE':
      return {
        ...state,
        isGameBrowserActive: action.isGameBrowserActive,
      };

    case 'BOTTOM_MENU_STATUS':
      return {
        ...state,
        bottomMenuStatus: action.bottomMenuStatus,
      };

    default:
      return state;
  }
}
