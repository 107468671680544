import {LOGIN_USER, LOGOUT_USER,} from '../constants/Auth';

export default function auth(
  state = { isAuthenticated: false },
  action,
) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };

    case 'REQUIRE_AUTHENTICATION':
      return {
        ...state,
        requireAuthentication: action.payload.requireAuthentication,
      };

    default:
      return state;
  }
}

export function authHasErrored(state = false, action) {
  switch (action.type) {
    case 'AUTH_HAS_ERRORED':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
export function authIsLoading(state = false, action) {
  switch (action.type) {
    case 'AUTH_IS_LOADING':
      return action.isLoading;
    default:
      return state;
  }
}
export function items(state = [], action) {
  switch (action.type) {
    case 'ITEMS_FETCH_DATA_SUCCESS':
      return action.items;
    default:
      return state;
  }
}
