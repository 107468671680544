import _ from 'lodash';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import queryString from 'query-string';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { calculateResponsiveState } from 'redux-responsive';
import Cookies from 'universal-cookie';
import isMobile from 'ismobilejs';
import { HelmetProvider } from 'react-helmet-async';
import { hook } from './actions/aspire';
import './core/storagePolyfill';
import { closeAllModals } from './actions/modal';
import router from './router';
import routerSync from './routerSync';
import history from './core/history';
import App from './components/App';
import configureStore from './store/configureStore';
import { deepForceUpdate, ErrorReporter } from './core/devUtils';
import { inIframe, scrollTo } from './core/utils';
import { closeNavigation } from './actions/navigation';
import { handleBtag, initBtag } from './actions/affiliate';
import { updateRoute } from './actions/router';
import { page } from './actions/analytics';
import { initDevice } from './actions/device';

import { TRACK_EVENTS } from './constants/Analytics';
import createApolloClient from './core/createApolloClient';
import createFetch from './createFetch';
import * as Sentry from '@sentry/react';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://cbefb1189abe4468af14ec127ad76e9c@o236536.ingest.sentry.io/1869125',
//     integrations: [new Sentry.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     replaysSessionSampleRate: 0.1,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,
//     tracesSampleRate: 1.0,
//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//       'localhost',
//       /^https:\/\/www\.playfrank\.com\/graphql/,
//       /^https:\/\/preprod\.playfrank\.com\/graphql/,
//     ],
//   });
// }
// Universal HTTP client
const fetch = createFetch(window.fetch, {
  baseUrl: window.App.apiUrl,
});

const apolloClient = createApolloClient();

// import DrawSVGPlugin from "./core/gsap/DrawSVGPlugin";

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
}

const cookies = new Cookies();
const container = document.getElementById('app');
let appInstance;
let currentLocation = history.location;
const queryParams = queryString.parse(location.search);

// ['en-gb', en, de].forEach(addLocaleData);

const store = configureStore(window.App.state, {
  fetch,
  history,
  isMobile: isMobile(),
  client: apolloClient,
});

const { btag } = queryParams;

// store.dispatch(hook());
// store.dispatch(setDefaultCurrency('GBP'));
// store.dispatch(startLivePush());

handleBtag(btag).then((resultBtag) => {
  store.dispatch(
    initBtag(resultBtag, cookies.get('btag'), !!cookies.get('btag')),
  );
  console.log('call hook');
  store.dispatch(hook());
  // store.dispatch(initWebSockets()).then(() => {
  //   store.dispatch(fetchAffiliateData());
  //   // store.dispatch(preloadCategories());
  //   // store.dispatch(initCookieControl());
  //   // store.dispatch(initUserSession());
  //   // store.dispatch(initMaintenance());
  // });
});

// store.dispatch(initLicense());
// calculate the initial state
store.dispatch(calculateResponsiveState(window));
store.dispatch(initDevice());

/* eslint-disable global-require */
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map((x) => x._insertCss());
  return () => {
    removeCss.forEach((f) => f());
  };
};

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store,
  // For react-apollo
  client: apolloClient,
  fetch,
  pathname: '',
  insertCss,
};

// Initate the base
// store.dispatch(setVisitingCountry());

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

let onRenderComplete = function initialRenderComplete() {
  const elem = document.getElementById('css');
  if (elem) elem.parentNode.removeChild(elem);
  onRenderComplete = function renderComplete(route, location) {
    let scrollX = 0;
    let scrollY = 0;
    const pos = scrollPositionsHistory[location.key];
    if (pos) {
      scrollX = pos.scrollX;
      scrollY = pos.scrollY;
    } else {
      const targetHash = location.hash.substr(1);
      if (targetHash) {
        window.setTimeout(() => {
          const targetId = document.getElementById(targetHash);
          const targetName = document.getElementsByName(targetHash)[0];

          if (targetId || targetName) {
            scrollTo(window, 0.5, {
              scrollTo: {
                y: targetId ? `#${targetHash}` : `[name="${targetHash}"]`,
                autoKill: false,
                offsetY: isMobile.any ? 60 : 0,
              },
            });
          }
        }, 500);
      }
    }

    // Restore the scroll position if it was saved into the state
    // or scroll to the given #hash anchor
    // or scroll to top of the page
    window.scrollTo(scrollX, scrollY);
  };
};

// Re-render the app when window.location changes

function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any

  const routerResolveParams = {
    ...context,
    pathname: location.pathname,
    query: queryParams,
    locale: store.getState().intl.locale,
    cookies: cookies.getAll(),
    noTransition: location?.state?.noTransition,
  };

  const processRoute = (route, location, action) => {
    // Dispatch our route, even if we don't transition it
    store.dispatch(
      updateRoute({ ..._.omit(route, ['component']), state: location.state }),
    );

    if (action === 'PUSH' || action === 'REPLACE') {
      if (
        (location.state &&
          location.state.hasOwnProperty('noTransition') &&
          location.state.noTransition) ||
        route.noTransition
      ) {
        return false;
      }

      store.dispatch(closeNavigation());
      store.dispatch(closeAllModals());
      delete scrollPositionsHistory[location.key];
    }

    currentLocation = location;

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      console.log('(currentLocation.key !== location.key)');
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const helmetContext = {};

    let app = (
      <HelmetProvider context={helmetContext}>
        <StyleContext.Provider value={{ insertCss }}>
          <App context={context}>{route.component}</App>
        </StyleContext.Provider>
      </HelmetProvider>
    );

    if (!appInstance) {
      // appInstance = hydrateRoot(container, app, () =>
      //   onRenderComplete(route, location)
      // );
      onRenderComplete(route, location);
      appInstance = hydrateRoot(container, app, {
        onRecoverableError: (e) => {
          console.log(e);
        },
      });
    } else {
      onRenderComplete(route, location);
      appInstance.render(app);
    }

    if (window.analytics) {
      if (window.analytics.invoked) {
        console.log('window.analytics.initialized');
        store.dispatch(page(route.name, null, {}));
      } else {
        window.analytics.ready(() => {
          console.log('window.analytics.onready');
          store.dispatch(page(route.name, null, {}));
        });
      }
    } else {
      console.log('window.analytics.false');
    }
    // }

    // register our service worker
    // store.dispatch(registerServiceWorker());

    // register the page to analytics
    // if (window.analytics) window.analytics.page(null, route.name);

    return route;
  };
  let route;

  // Traverses the list of routes in the order they are defined until
  // it finds the first route that matches provided URL path string
  // and whose action method returns anything other than `undefined`.
  try {
    route = routerSync.resolve(routerResolveParams);
  } catch (error) {
    route = router.resolve(routerResolveParams);
  }
  try {
    if (Promise.resolve(route) === route) {
      Promise.resolve(route).then((route) => {
        processRoute(route, location, action);
      });
    } else {
      processRoute(route, location, action);
    }
    // register our service worker
    // store.dispatch(registerServiceWorker());

    // register the page to analytics
  } catch (error) {
    // Display the error in full-screen for development mode
    if (__DEV__) {
      document.title = `Error: ${error.message}`;
      if (!appInstance) {
        // appInstance = hydrateRoot(container, app, () =>
        //   onRenderComplete(route, location)
        // );

        appInstance = hydrateRoot(container, <ErrorReporter error={error} />, {
          onRecoverableError: (e) => {
            console.log(e);
          },
        });
      } else {
        appInstance.render(<ErrorReporter error={error} />);
      }

      appInstance = null;

      throw error;
    }

    // window.analytics.track(`${TRACK_EVENTS.CLIENTERROR}`, { error: error });

    store.dispatch(
      page(`${TRACK_EVENTS.CLIENTERROR}`, null, {
        error,
        btag: queryParams.btag || cookies.get('btag'),
      }),
    );

    console.error(error); // eslint-disable-line no-console

    // Do a full page reload if error occurs during client-side navigation
    if (action && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

let isHistoryObserved = false;
export default function main() {
  // Handle client-side navigation by using HTML5 History API
  // For more information visit https://github.com/mjackson/history#readme
  currentLocation = history.location;
  if (!isHistoryObserved) {
    isHistoryObserved = true;
    history.listen(onLocationChange);
  }
  onLocationChange(currentLocation);
}

// globally accesible entry point
window.RSK_ENTRY = main;

// Handle errors that might happen after rendering
// Display the error in full-screen for development mode
// if (__DEV__) {
//   window.addEventListener('error', event => {
//     appInstance = null;
//     document.title = `Runtime Error: ${event.error.message}`;
//     ReactDOM.render(<ErrorReporter error={event.error} />, container);
//   });
// }

// Enable Hot Module Replacement (HMR)

if (module.hot) {
  module.hot.accept(0, () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }
    onLocationChange(currentLocation);
  });
}

if (inIframe()) {
  window.top.location = '/';
}
// Make sure we are not in an Iframe
/*
try {
  return window.self !== window.top;
} catch (e) {
  return true;
}
*/
