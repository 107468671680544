import Cookies from 'universal-cookie';

const cookies = new Cookies();
export function initLicense(forceLicense = null) {
  return (dispatch, getState) => {
    if (forceLicense) {
      dispatch({
        type: 'LOAD_LICENSE',
        payload: { type: forceLicense },
      });

      cookies.set('licenseType', forceLicense, {
        secure: true,
        path: '/',
        expires: new Date(new Date().setDate(new Date().getDate() + 14)),
      }); // 30 days btag
    } else {
      dispatch({
        type: 'LOAD_LICENSE',
        payload: { type: getState().intl.locale === 'en-gb' ? 'UK' : 'MGA' },
      });

      cookies.set(
        'licenseType',
        getState().intl.locale === 'en-gb' ? 'UK' : 'MGA',
        {
          secure: true,
          path: '/',
          expires: new Date(new Date().setDate(new Date().getDate() + 14)),
        },
      ); // 30 days btag
    }
  };
}
