import promotion from './promotion/promotion-overview.routes.json';
import promotionDetail from './promotion/promotion-detail.routes.json';
import limitedTimePromotions from './limited-time-promotion/promotion-overview.routes.json';
import limitedTimePromotion from './limited-time-promotion/promotion-detail.routes.json';
import home from './home/home.routes.json';
import touch from './game/touch-index.routes.json';
import liveGame from './game/live-index.routes.json';
import game from './game/game.routes.json';
import gameSeo from './gameSeo/gameSeo.routes.json';
import register from './register/register.routes.json';
import faq from './faq/faq.routes.json';
import about from './aspire-content/about.routes.json';
import responsibleGaming from './aspire-content/responsible-gaming.routes.json';
import subjectRights from './aspire-content/subject-rights.routes.json';
import bonusTerms from './aspire-content/bonus-terms.routes.json';
import privacyPolicy from './aspire-content/privacy-policy.routes.json';
import fairPlay from './aspire-content/fair-play.routes.json';
import cashOutPolicy from './aspire-content/cash-out-policy.routes.json';
import security from './aspire-content/security.routes.json';
import termsAndConditions from './aspire-content/terms-and-conditions.routes.json';
import cookiePolicy from './aspire-content/cookie-policy.routes.json';
import dailyChallenge from './dailyChallenge/daily-challenge.routes.json';
import vipClub from './vipClub/vip-club.routes.json';
import sitemap from './siteMap/sitemap.routes.json';
import payments from './payments/payments.routes.json';
import auth from './auth/auth.routes.json';
import aspireContent from './aspire-content/aspire-content.routes.json';

export default {
  aspireContent,
  auth,
  promotion,
  promotionDetail,
  limitedTimePromotions,
  limitedTimePromotion,
  home,
  payments,
  lobby: home,
  responsibleGaming,
  faq,
  about,
  touch,
  liveGame,
  game,
  gameSeo,
  register,
  subjectRights,
  bonusTerms,
  privacyPolicy,
  fairPlay,
  cashOutPolicy,
  security,
  termsAndConditions,
  cookiePolicy,
  dailyChallenge,
  vipClub,
  sitemap,
};
