/**
 * @todo remember old sroll position - so when we close the navigation we can scroll to old navigation
 */
export const openNavigation = () => (dispatch, getState) => {
  document.body.classList.add('navigationIsOpen');
  // window.scrollTo(0, 0);
  dispatch({
    type: 'NAVIGATION_IS_OPEN',
    isNavigationOpen: true,
    scrollPosition: window.pageYOffset,
  });
};

export const closeNavigation = () => (dispatch, getState) => {
  document.body.classList.remove('navigationIsOpen');
  // window.scrollTo(dispatch.scrollPosition, 0);
  dispatch({
    type: 'NAVIGATION_IS_CLOSED',
    isNavigationOpen: false,
  });
};

export const toggleNavigation = () => (dispatch, getState) => {
  if (getState().navigation.isNavigationOpen) {
    dispatch(closeNavigation());
  } else {
    dispatch(openNavigation());
  }
};

export const setGamebrowserActive = isActive => (dispatch, getState) => {
  dispatch({
    type: 'GAMEBROWSER_IS_ACTIVE',
    isGameBrowserActive: isActive,
  });
};

export const bottomMenuStatus = bottomMenuStatus => (dispatch, getState) => {
  dispatch({
    type: 'BOTTOM_MENU_STATUS',
    bottomMenuStatus,
  });
};
