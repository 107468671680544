import UniversalRouter from 'universal-router/sync';

import routes from './routes/index';
// import ampRoutes from './routes/amp';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (typeof context.route.action === 'function') {
      return context.route.action(
        { ...context, index: context.route.index, name: context.route.name },
        params,
      );
    }
    return undefined;
  },
});
/*
export const ampRouter = new UniversalRouter(ampRoutes, {
  resolveRoute(context, params) {
    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then(action => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});


*/
