import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { reducer as reduxFormReducer } from 'redux-form';
import { app } from './app';
import user from './user';
import { default as auth } from './auth';
import runtime from './runtime';
import intl from './intl';
import navigation from './navigation';
import { default as wallet } from './wallet';
import { default as notification } from './notification';
import { default as license } from './license';
import { gamebrowser } from './gamebrowser';
import { game } from './game';
import { analytics } from './analytics';
import { default as affiliate } from './affiliate';
import { default as xperience } from './xperience';
import { device } from './device';
import { default as message } from './message';
import { router } from './router';
import { modal } from './modal';
import { default as live } from './live';
import { default as livehelp } from './livehelp';
import { default as error } from './error';
import { default as cache } from './cache';
import { default as aspire } from './aspire';

export default function createRootReducer({ isMobile }) {
  return combineReducers({
    app,
    aspire,
    affiliate,
    auth,
    analytics,
    browser: createResponsiveStateReducer(
      {
        extraSmall: 480,
        small: 768,
        medium: 992,
        large: 1200,
        xl: 1599,
      },
      {
        initialMediaType: isMobile.any ? 'small' : 'infinity',
      },
    ),
    // browser: createResponsiveStateReducer(null),
    cache,
    device,
    error,
    notification,
    form: reduxFormReducer,
    game,
    gamebrowser,
    intl,
    license,
    live,
    livehelp,
    message,
    modal,
    navigation,
    router,
    runtime,
    user,
    wallet,

    xperience,
  });
}
