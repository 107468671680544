/* eslint-disable import/prefer-default-export */
export function getWallet() {
  const balance = window.AsgFW.getDisplayBalance();
  return {
    total: parseInt(balance.Balance, 10) / 100,
    totalCasinoWallet: parseInt(balance.RealBalance, 10) / 100,
    totalBonusWallet: parseInt(balance.BonusBalance, 10) / 100,
    lockedAmount: 0,
    loyalty: balance.Loyalty,
    currency: balance.currency,
  };
}
