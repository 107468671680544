/* eslint-disable import/prefer-default-export */

import {ssrCountries} from '../../config';

async function retrieveGameList(isMobile = false, country) {
  return new Promise((resolve, reject) => {
    try {
      const newEvo = [];
      if (process.env.BROWSER) {
        //let games;
        // if (window.AsgFWLoaded && !window.AsgFWGamesLoaded) {
        //   window.AsgFW.setOnGamesListUpdated(data => {
        //     console.log('setOnGamesListUpdated');
        //
        //   });
        // } else if (!window.AsgFWLoaded) {
        //   document.addEventListener('fw_asgReady', () => {
        //     console.log('fw_asgReady');
        //     window.AsgFW.setOnGamesListUpdated(data => {
        //       console.log('setOnGamesListUpdated');
        //       const games = window.AsgFW.getGamesList();
        //       resolve(games.instant);
        //     });
        //   });
        // } else {
        const games = (window.AsgFWGamesLoaded &&
          window?.AsgFW?.getGamesList()) || { instant: [] };
        const filteredGames = games.instant.filter((game) => {
          return (
            ![
              5134, 5442, 5225, 8641, 8642, 6633, 6631, 5680, 5678, 5676, 5674,
              5672, 5666, 5252, 5244, 5240, 6643, 6632, 5681, 5679, 5675, 5677,
              5673, 5667, 5253, 5245, 5241, 5427, 5129
            ].includes(game.id) &&
            game.name.toLowerCase().indexOf('roulette') === -1 &&
            game.name.toLowerCase().indexOf('blackjack') === -1
          );
        });
        resolve(filteredGames);
        //}
      }

      if (!process.env.BROWSER) {
        const response = fetch(
          `https://www.playfrank.com/feeds/games_${isMobile ? 'C' : 'W'}_${
            ssrCountries.indexOf(country) > -1 ? 'UK' : 'UK'
          }.json`,
        )
          .then((response) => response.json())
          .then((json) => resolve(json.instant));
      }
    } catch (e) {
      // console.log('error', e);
      if (e.type !== 'invalid-json') {
        const response = fetch(
          `https://www.playfrank.com/feeds/games_${isMobile ? 'C' : 'W'}_${
            ssrCountries.indexOf(country) > -1 ? country : 'UK'
          }.json`,
        )
          .then((response) => response.json())
          .then((json) => resolve(json.instant));
      } else if (!process.env.BROWSER) {
        const ssrCountries = ['US', 'UK', 'DE', 'CA', 'ZA', 'IN', 'NL', 'NO'];
        const games = require(`../../../public/feeds/games_${
          isMobile ? 'C' : 'W'
        }_${ssrCountries.indexOf(country) > -1 ? country : 'UK'}.json`);
        resolve(games.instant);
      }
    }
  });
}

export const getGameList = retrieveGameList;
