/* eslint-disable import/prefer-default-export */
import { getGameList } from './game';
import { getJackpots } from './jackpot';
import casinoMapperDesktop from './mapper-casino-category_desktop.json';
import liveCasinoMapperDesktop from './mapper-live-casino-category_desktop';
import casinoMapperMobile from './mapper-casino-category_mobile.json';
import liveCasinoMapperMobile from './mapper-live-casino-category_mobile';
// import liveTablesMapper from './mapper-livetables';
import extraTablesMapper from './mapper-live-casino-featured.json';
import { ssrCountries } from '../../config';

export async function getData(isMobile, country) {
  let categories = [];
  return new Promise((resolve, reject) => {
    try {
      if (process.env.BROWSER) {
        categories = window.AsgFW.getCategories();

        // get index of object with id of 37
        const removeIndex = categories[0].subCategories.findIndex(
          (item) => item.name === 'Scratch Cards',
        );
        // remove object
        if (removeIndex > 0) {
          categories[0].subCategories.splice(removeIndex, 1);
        }

        // // get index of object with id of 37
        // const removeIndexMobile = categories.findIndex(
        //   (item) => item.name === 'Games',
        // );
        // // remove object
        // console.log('removeIndex', removeIndexMobile);
        // if (removeIndexMobile > 0) {
        //   categories.splice(removeIndexMobile, 1);
        // }

        resolve(categories);
      }

      if (!process.env.BROWSER) {
        fetch(
          `${FEEDURL}/categories_${isMobile ? 'C' : 'W'}_${
            ssrCountries.indexOf(country) > -1 ? country : 'UK'
          }.json`,
        )
          .then((response) => response.json())
          .then((categories) => resolve(categories));
      }
    } catch (e) {
      resolve([
        { name: 'Casino', defaultSubCategoryIndex: 0, subCategories: [] },
      ]);
      console.log(e);
      // if (e.type !== 'invalid-json') {
      //   fetch(
      //     `${FEEDURL}/categories_${isMobile ? 'C' : 'W'}_${
      //       ssrCountries.indexOf(country) > -1 ? country : 'UK'
      //     }.json`,
      //   )
      //     .then((response) => response.json())
      //     .then((categories) => resolve(categories));
      // } else if (!process.env.BROWSER) {
      //   const ssrCountries = ['US', 'UK', 'DE', 'CA', 'ZA', 'IN', 'NL', 'NO'];
      //   categories = require(`../../../public/feeds/categories_${
      //     isMobile ? 'C' : 'W'
      //   }_${ssrCountries.indexOf(country) > -1 ? country : 'UK'}.json`);
      //
      //   resolve(categories);
      // }
    }
  });
}

async function getCategories(isMobile = false, country) {
  return getData(isMobile, country).then((categories) => {
    if (!isMobile) {
      return categories;
    }

    const subCategoriesCasino = [];
    const subCategoriesLive = [];
    categories.map((item, index) => {
      if (item.name.indexOf('Live') > -1) {
        subCategoriesLive.push(...item?.subCategories);
      } else {
        subCategoriesCasino.push(...item?.subCategories);
      }
    });

    return [
      {
        defaultSubCategoryIndex: 0,
        name: 'casino',
        subCategories: subCategoriesCasino,
      },
      {
        defaultSubCategoryIndex: 0,
        name: 'Live casino',
        subCategories: subCategoriesLive,
      },
    ];
  });
}

// function getCategories(isMobile = false) {
//   const categories = [];
//   try {
//     const categories = window.AsgFW.getCategories();
//   } catch(e) {
//     console.log('category');
//   }
//   if (!isMobile) {
//     return categories;
//   }
//
//   const subCategoriesCasino = [];
//   const subCategoriesLive = [];
//   categories.map((item, index) => {
//     if (index === 2) {
//       subCategoriesLive.push(...item.subCategories);
//     } else {
//       subCategoriesCasino.push(...item.subCategories);
//     }
//   });
//
//   return [ { defaultSubCategoryIndex: 0, name: 'casino', subCategories: subCategoriesCasino }, { defaultSubCategoryIndex: 0, name: 'Live casino', subCategories: subCategoriesLive }];
// }

async function getCategory(category = 'CASINO', isMobile = false, country) {
  const categoriesMapper = {
    CASINO: 0,
    LIVECASINO: 1,
  };

  const categoriesConfig = {
    CASINO: isMobile ? casinoMapperMobile : casinoMapperDesktop,
    LIVECASINO: isMobile ? liveCasinoMapperMobile : liveCasinoMapperDesktop,
  };

  const games = await getGameList(isMobile, country);
  const categories = await getCategories(isMobile, country);
  const { mainCategories = [] } = categories || {};

  let { subCategories } = categories?.[categoriesMapper?.[category]] || {
    defaultSubCategoryIndex: 0,
    name: 'Casino',
    subCategories: [],
  };
  // const itemInstantGamesOrder = [Object.hasOwnProperty.call(subCategories[0].instantGamesOrder, country)  ? [...new Set(subCategories[0].instantGamesOrder[country])] : subCategories[0].instantGamesOrder.default;
  //

  subCategories =
    category === 'LIVECASINO'
      ? isMobile
        ? [
            ...categoriesConfig.LIVECASINO,
            ...[{ ...subCategories[0], instance: 'ALL' }],
          ]
        : [...subCategories, ...extraTablesMapper]
      : subCategories;

  console.log('subCategories', subCategories);
  // .filter(({ externalGameSubProvider }) => externalGameSubProvider === 'Evolution Gaming')
  return subCategories.map((item, key) => {
    const instantGamesOrder = [];
    const itemInstantGamesOrder =
      categoriesConfig[category] &&
      categoriesConfig[category][key] &&
      categoriesConfig[category][key].instantGamesOrder &&
      (Object.hasOwnProperty.call(
        categoriesConfig[category][key].instantGamesOrder,
        country,
      ) ||
        Object.hasOwnProperty.call(
          categoriesConfig[category][key].instantGamesOrder,
          'default',
        ))
        ? [
            ...new Set([
              ...(categoriesConfig[category][key].instantGamesOrder[country] ||
                categoriesConfig[category][key].instantGamesOrder.default),
              ...(item.instantGamesOrder.default || item.instantGamesOrder),
            ]),
          ]
        : item.instantGamesOrder;

    itemInstantGamesOrder &&
      itemInstantGamesOrder.map((gameID) => {
        const fullGame = games && games.find(({ id }) => id === gameID);
        if (category === 'LIVECASINO') {
          if (fullGame?.externalGameSubProvider === 'Evolution Gaming') {
            instantGamesOrder.push({ ...fullGame, isOpen: true });
          } else if (fullGame) {
            instantGamesOrder.push({ ...fullGame, isOpen: true });
          }
        } else if (fullGame)
          instantGamesOrder.push({ ...fullGame, isOpen: true });

        // if (category === 'LIVECASINO' && liveTablesMapper[gameID] !== undefined) {
        //   liveTablesMapper[gameID].map((extraFields) => {
        //     instantGamesOrder.push({ ...fullGame, isOpen: true, ...extraFields });
        //   });
        // } else {

        // }
      });

    console.log('instantGamesOrder', instantGamesOrder);
    console.log(
      '{ ...item, ...categoriesConfig[category][key], instantGamesOrder }',
      { ...item, ...categoriesConfig[category][key], instantGamesOrder },
    );
    return { ...item, ...categoriesConfig[category][key], instantGamesOrder };
  });
}

async function getSubCategory(
  mainCategory = 'CASINO',
  category = 'ALL',
  isMobile = false,
  country,
) {
  if (isMobile && category === 'NEWGAMES') {
    const games = await getGameList(isMobile, country);
    const newGames = games.filter(
      ({ gameConfig }) => gameConfig.indexOf('New') > -1,
    );
    newGames.sort((a, b) => b.id - a.id);
    return {
      name: 'New Games',
      instance: 'NEWGAMES',
      instantGamesOrder: newGames,
    };
  }

  // if (isMobile && category === 'ALL') {
  //   const games = await getGameList(isMobile, country);
  //
  //   return {
  //     name: 'All Games',
  //     instance: 'ALL',
  //     instantGamesOrder: games,
  //   };
  // }

  // console.log('instance', instance);
  console.log('category', category);
  const subcategories = await getCategoryMem(mainCategory, isMobile, country);

  console.log('subcategories', subcategories);
  return subcategories.find(({ instance }) => instance === category);
}

export const getCategoryMem = getCategory;
export const getSubCategoryMem = getSubCategory;
