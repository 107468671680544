export default function license(
  state = {
    type: 'GB',
    showResponsibleGamingModal: false,
    showSeperateFundsModal: false,
    showRealityCheckSetModal: false,
    hasPromotions: true,
    hasDemoGames: false,
    hasLoyaltyProgram: false,
    hasStatusBar: false,
  },
  action,
) {
  switch (action.type) {
    case 'LOAD_LICENSE':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
