/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import Cookies from 'universal-cookie';

import _ from 'lodash';
import React from 'react';
import slugify from 'slugify';
import { get, isObject } from '../core/utils';
import { initUserSession } from './user';
import {
  getGameVendors,
  setAspireGameData,
  setGameCategory,
} from './gamebrowser';
import { initWallet } from './wallet';
import { setOnSessionStatusUpdate } from '../data/model/session';
import history from '../core/history';
import { localeUrl, url } from '../core/url';
import { closeAllModals, openModal } from './modal';
import { linkMapping } from '../core/aspire';
import { trackAction } from './analytics';
import { logUserOut } from './auth';
import getLiveCasinoBetlimitsQuery from './get-live-casino-betlimits.graphql';
import getLiveCasinoStatesQuery from './get-live-casino-states.graphql';
import getJackpotsQuery from './get-jackpots.graphql';
import getFeaturedGamesQuery from './get-featured-games.graphql';
import contactIcon from '../../public/img/contact-us-icon.svg';
import differenceInHours from 'date-fns/differenceInHours';
import parseISO from 'date-fns/parseISO';

const cookies = new Cookies();
const autoComplete = true; // %20 probability of get "true"

function loadChat() {
  window.AsgFW.initChat({
    siteLanguage: 'en',
    preChatTexts: {
      preChatHeaderText: 'CHAT WITH US',
      preChatBannerText:
        "We can't wait to talk to you. But first, please take a moment to tell us a bit about yourself",
      preChatStartChat: 'Start Chat',
      preChatName: 'Name',
      preChatNameError: 'Please enter your name and surname',
      preChatEmail: 'E-mail',
      preChatEmailError: 'Please enter a valid e-mail',
    },
  });
}

export const setEngagedBannerDetails = (bannerData) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ENGAGGED_BANNER_DETAILS',
      payload: bannerData,
    });
  };
};

export function startLiveGames() {
  return async (dispatch, getState, { client }) => {
    // const fullTableDb = {};
    // client
    //   .query({
    //     query: getLiveCasinoBetlimitsQuery,
    //   })
    //   .then((result) => {
    //     // console.log('result', result);
    //   });
    // client
    //   .watchQuery({
    //     query: getLiveCasinoStatesQuery,
    //     pollInterval: 10000,
    //     fetchPolicy: 'no-cache',
    //   })
    //   .subscribe(
    //     (result) => {
    //       if (result?.data?.getLiveCasinoStates?.tables) {
    //         dispatch({
    //           type: 'INIT',
    //           payload: {
    //             tables: result.data.getLiveCasinoStates.tables,
    //             isLoaded: true,
    //           },
    //         });
    //       }
    //     },
    //     (err: any) => {
    //       console.log('Error loading data');
    //     },
    //   );
  };
}

export function asgFrameworkLoad() {
  return async (dispatch) => {
    dispatch({
      type: 'ASG_FRAMEWORK_LOADED',
      payload: {
        isFrameworkLoaded: true,
      },
    });
  };
}

export function asgGamesLoad() {
  return async (dispatch) => {
    dispatch({
      type: 'ASG_GAMES_LOADED',
      payload: {
        isGamesLoaded: true,
      },
    });
  };
}

export function bootstrap() {
  return async (dispatch, getState, { client }) => {
    console.log('calling bootstrap');
    // const intercept = (urlmatch, newurl) => {
    //   const open = XMLHttpRequest.prototype.open;
    //   XMLHttpRequest.prototype.open = function(method, url, ...rest) {
    //     url = url.replace(urlmatch, newurl);
    //     return open.call(this, method, url, ...rest);
    //   };
    // };

    // intercept(
    //   'https://gamesrv1.playfrank.com/',
    //   'https://gamesrvplf.playfrank.com/',
    // );

    const ARParam = {
      '717493_105336': '717493SWEETB',
      '717493_110870': '717493Gonzo',
      '717493_110871': '717493Reacto',
      '717493_112553': '717493BOD',
      // '717493_113186': '717493WOLF',
      '717493_143281': '717493FLUFF',
      '717493_143252': '717493BIGBAS',
      '717493_113186': '717493WGOLD',
      '717493_143269': '717493OLYMP',
      '717493_135492': '717493DOG',
      '717493_269982': '717493FLUFF',
    };

    const url = new URL(window.location.href);
    const btag = url.searchParams.get('btag');
    let sourceBtag = '';
    if (btag && btag !== '') {
      const splitted = btag.split('-');
      if (splitted.length === 0) {
        sourceBtag = btag;
      } else {
        sourceBtag = splitted[0];
      }
    }

    const brandID = '279';
    // var brandID = '27';

    const language = getState().intl.alpha3;
    const platform = getState().device.isMobile ? 'C' : 'W';
    const currency = getState().wallet.baseCurrency;
    const brandDomain = 'playfrank.com';
    const country = getState().user.IpCountry.toLowerCase();
    const isGameStandaloneMode = !!getState().device.isMobile;
    const urlParams = `AR=${
      getState().affiliate.id
        ? _.get(sourceBtag, ARParam, getState().affiliate.id)
        : ''
    }&PAR=${btag || ''}&${window.location.search.replace('?', '')}`;

    const params = {
      BrandID: brandID,
      Language: language,
      Platform: platform,
      URLParams: urlParams,
      Currency: currency,
      BrandDomain: brandDomain,
      // IsGameStandaloneMode: isGameStandaloneMode,
      IsGameStandaloneMode: false,
      Country: country,
      OpenPageFunc(key, customInfo) {
        const event = new CustomEvent('OpenPageFunc', {
          detail: { key, customInfo },
        });
        document.dispatchEvent(event);
      },
    };
    window.AsgFW = AsgFW;
    window.AsgFW.init(params);
    window.AsgFW.initAspireEngage(
      'https://crm-api.playfrank.com',
      '844ec1cc-0962-11eb-918c-46670b609988',
      'web.com.s2c',
    );
    document.addEventListener('fw_asgReady', () => {
      if (window.AsgFWLoaded) {
        return;
      }
      dispatch(asgFrameworkLoad());
      window.AsgFWLoaded = true;
      window.AsgFWChatInitiated = false;
      const showChatOriginal = window.AsgFW.showChat;

      window.AsgFW.showChat = () => {
        if (!window.AsgFWChatInitiated) {
          window.AsgFW.initChat({
            siteLanguage: 'en',
            preChatTexts: {
              preChatHeaderText: 'CHAT WITH US',
              preChatBannerText:
                "We can't wait to talk to you. But first, please take a moment to tell us a bit about yourself",
              preChatStartChat: 'Start Chat',
              preChatName: 'Name',
              preChatNameError: 'Please enter your name and surname',
              preChatEmail: 'E-mail',
              preChatEmailError: 'Please enter a valid e-mail',
            },
            uiTexts: {
              csatQuestion:
                'Were you satisfied with the level of service I provided you on chat today?',
            },
          });
          window.AsgFWChatInitiated = true;
        }
        if (!window.AsgFWChatLoaded) {
          console.log('chat setTimeout');
          window.setTimeout(window.AsgFW.showChat, 500);
        } else {
          window.AsgFW.showChat = showChatOriginal;
          window.AsgFW.showChat();
        }
      };

      document.addEventListener('ChatReady', () => {
        // chat functionality is ready
        // show chat button on page
        console.log('chatready');
        window.AsgFWChatLoaded = true;
      });

      let prevData = null;
      window.AsgFW.setOnGamesListUpdated(async (data) => {
        try {
          const sameObject = JSON.stringify(data) === JSON.stringify(prevData);
          if (sameObject) {
            return;
          }
          console.log('sameObject', sameObject);
        } catch (e) {
          console.log('sameObject', e);
        }
        prevData = data;
        console.log('setOnGamesListUpdated start');

        console.time('prepare setOnGamesListUpdated');
        //const details = AsgFW.getSessionDetails();

        // const jackpots = await client.query({
        //   query: getJackpotsQuery,
        //   variables: { country },
        // });

        //const now = new Date();
        //const difference = differenceInHours(parseISO(details.PlayerRegistrationDate), now);
        let featuredList = null;
        // if(window.AsgFW.isLoggedIn() && difference < 48) {
        //   featuredList = await client
        //     .query({
        //       query: getFeaturedGamesQuery,
        //     });
        // }

        let transformedData = {
          categories: { mainCategories: [{ subCategories: [] }] },
          games: data.games,
        };
        if (getState().device.isMobile) {
          // let's transform data
          transformedData.categories.mainCategories[0] = {
            name: 'casino',
            defaultSubCategoryIndex: 0,
            subCategories: [],
          };
          data.categories.mainCategories.map((mainCategory) => {
            transformedData.categories.mainCategories[0].subCategories.push(
              mainCategory.subCategories[0],
            );
          });
        } else {
          transformedData = data;

          if (transformedData?.categories?.mainCategories?.[1]) {
            //if(window.AsgFW.isLoggedIn() && featuredList  && difference < 48) {
            // to enable
            //transformedData.categories.mainCategories[0].subCategories[0] = featuredList?.data?.getFeaturedGamesList ?? {instantGamesOrder: []}
            //}
            transformedData.categories.mainCategories[0].subCategories.splice(
              transformedData.categories.mainCategories[0].subCategories
                .length - 1,
              0,
              {
                name: 'Game Shows',
                instantGamesOrder:
                  transformedData.categories.mainCategories[1].subCategories[0]
                    .instantGamesOrder,
              },
            );
          }
        }

        await dispatch(
          setAspireGameData(transformedData.categories, transformedData.games),
        );
        await dispatch(
          setGameCategory(
            'casino',
            getState().router?.activeRoute?.params?.category ?? '',
          ),
        );
        window.AsgFWGamesLoaded = true;
        dispatch(asgGamesLoad());
        dispatch(getGameVendors(data.games));
        console.timeEnd('prepare setOnGamesListUpdated');
        let sameObject = false;
        try {
          sameObject =
            JSON.stringify(transformedData) ===
            JSON.stringify(window?.App?.state?.gamebrowser?.gameData);
        } catch (e) {}
        try {
          if (!sameObject) {
            fetch('/api/ssr-games', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                gameData: transformedData,
                country,
                isMobile: getState().device.isMobile,
                isAuthenticated: getState().auth.isAuthenticated,
              }),
            });
          }
        } catch (e) {
          console.log('/api/ssr-games', e);
        }
      });

      if (!window.AsgFW.callTakeABreak) {
        window.AsgFW.callTakeABreak = () => {
          window.AsgFW.logout();
        };
      }
    });

    // if (window.AsgFWLoaded) {
    //   if (!loaded) {
    //
    //   }
    // } else {
    //   document.addEventListener('fw_asgReady', () => {
    //     if (!loaded) {
    //       this.loadChat();
    //     }
    //   });
    // }
  };
}

export function hook() {
  return async (dispatch, getState, { AutobahnReact }) => {
    console.log('calling hook');
    dispatch(bootstrap());
    dispatch(startLiveGames());
    document.addEventListener('fw_asgReady', () => {
      // $(document).trigger('nghs.onGamesListUpdated');
      // https://gamesrv1.playfrank.com/ScratchCards/sapi.aspx?AFI=279&AR=3188960_79640&CSI=279&GID=1802&ISID=6B47990F3C05B0573A6FBF93E5B3397E&IUA=neow&LNG=ENG&MMI=0&PAR=3188960_79640-wewrwre_werwerwrw_ewr-wer-wr-wr-we-w_WErewrw&PlayMode=M&PlayerID=11540814115408&UniqueDeviceId=92dcc376-5c31-4ac1-ae2b-820372590321&ci_LanguageCode=en&cm=SGPS&rst=j&uniqueNoCache=15814223412995251
      // https://gamesrv1.playfrank.com/ScratchCards/sapi.aspx?AFI=279&AR=3188960_79640&CSI=279&GID=1802&ISID=BB843268B47E6E6F0370A66114031740&IUA=neow&LNG=ENG&MMI=0&PAR=3188960_79640-wewrwre_werwerwrw_ewr-wer-wr-wr-we-w_WErewrw&PlayMode=M&PlayerID=11540814115408&UniqueDeviceId=92dcc376-5c31-4ac1-ae2b-820372590321&ci_LanguageCode=en&cm=SGPS&rst=j&uniqueNoCache=1581431542665

      //   if(process.env.NODE_ENV !== "production") {
      //
      //     //https://lob.egcvi.com/thumbnail/bj_agt1_med_L.jpg?q=1581544270709erw
      //     client
      //       .query({
      //         query: getEvolutionSessionQuery,
      //       })
      //       .then(result => {
      //         console.log('result', result);
      //
      //         let socket = new WebSocket(`wss://livecasino.gamesrv1.com/public/lobby/player/socket?messageFormat=json&EVOSESSIONID=${result.data.getEvolutionSession.session}`);
      //
      //         socket.onopen = function (e) {
      //           //alert("[open] Connection established");
      //           // alert("Sending to server");
      //           socket.send(JSON.stringify({
      //             "id": "twc5ca85zh",
      //             "type": "lobby.updateSubscriptions",
      //             "args": {"subscribeTopics": [{"topic": "category", "game": "roulette"}], "unsubscribeTopics": []}
      //           }));
      //
      //           socket.send(JSON.stringify({"id":"dnlnff0cc0","type":"settings.read","args":{"key":"lobby.common"}}));
      //           socket.send(JSON.stringify({"id":"u5qdcheh3e","type":"settings.read","args":{"key":"generic.mobile"}}));
      //           socket.send(JSON.stringify({"id":"9yc8dtzfyu","type":"settings.read","args":{"key":"generic.common"}}));
      //           socket.send(JSON.stringify({"id":"cmle912lph","type":"settings.read","args":{"key":"lobby.common"}}));
      //           socket.send(JSON.stringify({"id":"cmle912lph","type":"settings.read","args":{"key":"lobby.categories"}}));
      //            //socket.send(JSON.stringify({"id":"cmle912lph","type":"settings.read","args":{"key":"lobby.tables"}}));
      //           //
      //           //
      //           //
      //            socket.send(JSON.stringify({"log":{"type":"CLIENT_SOCKET_CONNECTION_ESTABLISHED","latency":-1,"value":{"currency":"EUR","gameType":"lobby","channel":"PCMac","orientation":"landscape","userAgent":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.87 Safari/537.36","inGame":false}}}));
      //           //
      //           //
      //           //
      //           // //blackjack:
      //           //
      //           // //{"id":"l9ix0q0bgj","type":"lobby.updateSubscriptions","args":{"subscribeTopics":[{"topic":"table","tables":[{"tableId":"mjq6qv3a6byakg23"},{"tableId":"i5j1cyqhrypkih23"},{"tableId":"9f4xhuhdd005xlbl"},{"tableId":"qlrc3fq3v7p6awm4"},{"tableId":"qckwjf2o52r9ikeb"},{"tableId":"gazgtkid9h1b0dn9"},{"tableId":"lnofpmm3756qae2c"},{"tableId":"0mvn914lkmo9vaq8"},{"tableId":"cpxl81x0rgi34cmo"},{"tableId":"o3d9tx3u8kd0yawc"},{"tableId":"psm2um7k4da8zwc2"},{"tableId":"s63nx2mpdomgjagb"}]}],"unsubscribeTopics":[]}}
      //
      //
      //           socket.send(JSON.stringify({
      //             "id": "hgsunpegy7",
      //             "type": "lobby.updateSubscriptions",
      //             "args": {
      //               "subscribeTopics": [{
      //                 "topic": "table",
      //                 "tables": [{"tableId": "LightningTable01"}, {"tableId": "vctlz20yfnmp1ylr"}, {"tableId": "MOWDream00000001"}, {"tableId": "7x0b1tgh7agmf6hv"}, {"tableId": "leqhceumaq6qfoug"}, {"tableId": "TopCard000000001"}]
      //               }], "unsubscribeTopics": []
      //             }
      //           }));
      //           socket.send(JSON.stringify({"id":"cmle912lph","type":"settings.read","args":{"key":"lobby.tables"}}));
      //         };
      //
      //         socket.onmessage = function (event) {
      //           console.log(`[message] Data received from server: ${event.data}`);
      //         };
      //
      //         socket.onclose = function (event) {
      //           if (event.wasClean) {
      //             //alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      //           } else {
      //             // e.g. server process killed or network down
      //             // event.code is usually 1006 in this case
      //             //alert('[close] Connection died');
      //           }
      //         };
      //
      //         socket.onerror = function (error) {
      //           alert(`[error] ${error.message}`);
      //         };
      //
      //       });
      //
      //
      //
      //
      // }

      // console.log('hook');
      // var myModule = angular.module('myModule', []);
      //
      // myModule.run(function($rootScope) {
      //   console.log('set config');
      //   $rootScope.$on('$locationChangeStart', function (event, next) {
      //     console.log('block');
      //       return event.preventDefault();
      //   })
      // })
      //
      // var $injector = window.angular.injector(['ng', 'myModule']);
      // $injector.invoke(function($rootScope, $compile, $document) {
      //   $compile($document)($rootScope);
      //   $rootScope.$digest();
      //   $rootScope.$apply();
      // });

      let handlerAttached = false;
      let registrationStarted = false;
      const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.attributeName === 'key' &&
            mutation?.target.attributes.getNamedItem('key').value ===
              'reg_step2_offers_bonus'
          ) {
            mutation.target.innerHTML =
              '<span class="">Yes, send me special bonus offers!</span>';
          }

          if (
            mutation.attributeName === 'style' &&
            mutation.type === 'attributes' &&
            mutation.target &&
            mutation.target.classList.contains('mwc-modal-mwc-content')
          ) {
            const errorMsg = document.getElementsByClassName(
              'mwc-modal-mwc-content',
            )[0];
            const error = errorMsg?.textContent;

            if (error && errorMsg?.textContent?.indexOf('self excluded') > -1) {
              dispatch(
                trackAction('Register last SE error', {
                  variation: autoComplete ? 'Autocomplete' : 'Default',
                  error,
                }),
              );
            }
          }

          if (
            mutation.attributeName === 'style' &&
            mutation.type === 'attributes' &&
            mutation.target &&
            mutation.target.classList.contains('mwc-reg-error-message')
          ) {
            const errorMsg = document.getElementsByClassName(
              'mwc-reg-error-message',
            )[0];
            const error = errorMsg?.getAttribute?.('message');

            if (error) {
              dispatch(
                trackAction('Register last step error', {
                  variation: autoComplete ? 'Autocomplete' : 'Default',
                  error,
                }),
              );
            }
          }

          if (
            mutation.attributeName === 'style' &&
            mutation.type === 'attributes' &&
            mutation.target &&
            mutation.target.classList.contains('asg--contact-us-modal')
          ) {
            const button = document.getElementsByClassName(
              'asg--contact-us-email-btn',
            )[0];
            button.onclick = (e) => {
              e.preventDefault();
              document
                .getElementsByClassName('asg--contact-us-close')[0]
                ?.click();
              const event = new CustomEvent('OpenPageFunc', {
                detail: { key: 'email' },
              });
              document.dispatchEvent(event);
            };
          }

          if (
            mutation.type === 'attributes' &&
            mutation.target.id === 'address' &&
            document.body.classList.contains('country-UK') &&
            autoComplete
          ) {
            // setTimeout(() => {

            if (!handlerAttached) {
              const scope = angular
                .element(document.getElementsByClassName('mwc-reg-form')[0])
                .scope();

              scope.regModel.data.gender = 'male';
              handlerAttached = true;

              const newText = document.createElement('label'); // create new textarea
              newText.id = 'postcoder_autocomplete_label';
              newText.innerText = 'Start typing your address or postcode here';
              newText.setAttribute('htmlFor', 'postcoder_autocomplete');
              mutation.target.setAttribute(
                'placeholder',
                'Start typing your address or postcode here',
              );
              mutation.target.setAttribute('autocomplete', 'xyz');
              mutation.target.setAttribute('autofill', 'no');
              // mutation.target.setAttribute('placeholder', "E.g. Manor Farm Barns, Fox Road");
              mutation.target.setAttribute('autoCorrect', 'off');
              mutation.target.setAttribute('autoCapitalize', 'off');
              mutation.target.setAttribute('spellCheck', 'false');
              // mutation.target.parentNode.insertBefore(
              //   newText,
              //   mutation.target.nextSibling,
              // );

              // Attach autocomplete to search box, with our settings
              // To get your free trial API key visit https://www.alliescomputing.com/postcoder/sign-up
              const postcoder_complete = new AlliesComplete(mutation.target, {
                apiKey: 'PCWL8-8CS73-M22NT-4D3HE', // Change this to your own API key
                autocompleteLabel: '',
                autocompleteWrapper: mutation.target.parentElement,
              });

              // This event is fired by library when user selects an item in the list
              mutation.target.addEventListener(
                'postcoder-complete-selectcomplete',
                function (e) {
                  auto_address_select(e.address);
                },
              );

              // Demo function to populate form fields with address fields from chosen address
              function auto_address_select(the_address) {
                scope.regModel.data.city = the_address.posttown;
                scope.regModel.data.address = `${the_address.addressline1}${
                  the_address.addressline2
                    ? `, ${the_address.addressline2}`
                    : ``
                }`;
                scope.regModel.data.zipCode = the_address.postcode;

                scope.$apply();
                // document.getElementById("organisation_auto").value = the_address.organisation || "";
                // document.getElementById("address_line_1_auto").value = the_address.addressline1 || "";
                // document.getElementById("address_line_2_auto").value = the_address.addressline2 || "";
                // document.getElementById("city_auto").value = the_address.posttown;
                // document.getElementById("county_auto").value = the_address.county;
                // document.getElementById("postcode_auto").value = the_address.postcode;

                //                mutation.target.value = "";
                mutation.target.blur();

                return true;
              }
            }

            // }, 5000);
          }
          if (
            mutation.attributeName === 'class' &&
            mutation.type === 'attributes' &&
            mutation.target &&
            mutation.target.classList.contains('mwc-login-form')
          ) {
            const loginForm =
              document.getElementsByClassName('mwc-login-form')[0];
            const scope =
              angular
                .element(document.getElementsByClassName('mwc-login-form')[0])
                .scope() || {};
            if (!scope.originalLogin) {
              scope.originalLogin = scope.login;
            }

            scope.login = () => {
              localStorage.setItem('USERNAME', scope.user.username);
              scope.originalLogin();
            };
          } else if (
            mutation.attributeName === 'class' &&
            mutation.type === 'attributes' &&
            mutation.target &&
            mutation.target.classList.contains('mwc-reg-form')
          ) {
            const scope = angular
              .element(document.getElementsByClassName('mwc-reg-form')[0])
              .scope();

            if (!registrationStarted) {
              registrationStarted = true;
              dispatch(
                trackAction('Register modal open', {
                  variation: autoComplete ? 'Autocomplete' : 'Default',
                }),
              );
            }
            if (
              scope &&
              scope.regform &&
              scope.regform.$$controls &&
              scope.regform.$$controls[1] &&
              scope.regform.$$controls[1].$name &&
              scope.regform.$$controls[1].$name === 'username' &&
              !scope.regform.$$controls[1].$asyncValidators
                .originalUniqueUserName
            ) {
              scope.regform.$$controls[1].$asyncValidators.originalUniqueUserName =
                scope.regform.$$controls[1].$asyncValidators.uniqueUserName;
            }

            if (
              scope &&
              scope.regform &&
              scope.regform.$$controls &&
              scope.regform.$$controls[1] &&
              scope.regform.$$controls[1].$name &&
              scope.regform.$$controls[1].$name === 'username'
            ) {
              scope.regform.$$controls[1].$asyncValidators.uniqueUserName = (
                ...params
              ) => {
                localStorage.setItem(
                  'USERNAME',
                  scope.regform.username.$viewValue,
                );
                return scope.regform.$$controls[1].$asyncValidators.originalUniqueUserName(
                  ...params,
                );
              };
            }
          }
        });
      });

      mutationObserver.observe(document.documentElement, {
        attributes: true,
        characterData: false,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: false,
      });

      // Framework loaded, notify app were are loaded and get games
      dispatch(initUserSession());

      setOnSessionStatusUpdate(
        () => {
          dispatch(initUserSession());
        },
        () => {
          cookies.remove('isLoggedIn');
          cookies.remove('PlayerID');
          cookies.remove('SessionToken');
          cookies.remove('EncryptedUserID');
          dispatch(logUserOut());
        },
      );

      window.setInterval(window.AsgFW.keepAlive, 60000 * 5); // refresh session  every 5 mins

      window.setInterval(() => {
        const isLoggedIn = window.AsgFW.isLoggedIn();
        if (!isLoggedIn && getState().auth.isAuthenticated) {
          dispatch(logUserOut());
        }

        if (getState().auth.isAuthenticated) {
          const event = new CustomEvent('fw_balanceUpdated');
          document.dispatchEvent(event);

          cookies.set('isLoggedIn', 1, {
            maxAge: 60 * 60,
            secure: true,
          });
        }
      }, 5000); // refresh session  every 5 secs

      document.addEventListener('fw_showChat', window.AsgFW.showChat);

      /*
      // Add mobile and Netent ingame special behaviour on game start.
      AsgFW.setLoadGameStart(function(gameId, playMode) { // Detect that the player is using the mobile platform if (getFrameworkPreferences().Platform === ‘C’) {
// Embed panic pane into netent game header
        if (AsgFW.getGamesList().instant.find((el => el.id === gameId)).externalGameProvider === 'NetEnt') { // Add your panic button code
          $('#ng-game-container').before(“<div class=’panic-panel’>Spielpause</div>”); }
        } });
        // Remove mobile and Netent ingame special behaviour on game end.
        AsgFW.setLoadGameClosed(function(gameId, playMode) { $('.panic-panel').remove();
        });
       */
      const previousTitle = '';
      window.AsgFW.setLoadGameStart((id) => {
        const game = window.AsgFW.getGameById(id);

        // first get the current window title

        // dispatch(getGameById(game)).then(game => {
        dispatch({
          type: 'GAME_LOADING',
          payload: {
            ...game,
          },
        });

        console.log('start game');
        if (game.externalGameProvider !== 'NetEnt') {
          if (document.getElementsByClassName('TopBar').length > 0)
            document.getElementsByClassName('TopBar')[0].style.display = 'none';
        }

        if (document.getElementsByClassName('headroom-wrapper').length > 0)
          document.getElementsByClassName('headroom-wrapper')[0].style.display =
            'none';

        dispatch({
          type: 'GAME_LOADED',
          payload: { isActive: true },
        });

        history.push(
          localeUrl('game', getState().intl.locale, {
            slug: game.id || '602',
            tableId: game.tableID,
            ...(game?.name && { name: slugify(game?.name, { lower: true }) }),
          }),
          {
            noTransition: true,
          },
        );

        // });
      });

      window.AsgFW.setLoadGameClosed(() => {
        document.title = previousTitle;

        console.log(
          'getState().router.previousRoute.name',
          getState().router.previousRoute.name,
        );
        dispatch({
          type: 'GAME_UNLOAD',
          payload: { isActive: false },
        });
        if (document.getElementsByClassName('headroom-wrapper').length > 0) {
          document.getElementsByClassName('headroom-wrapper')[0].style.display =
            'flex';
        }

        if (document.getElementById('TopBar')) {
          document.getElementById('TopBar').style.display = 'flex';
        }
        if (document.getElementsByClassName('TopBar').length > 0) {
          document.getElementsByClassName('TopBar')[0].style.display = 'flex';
        }

        // @todo, fix the noTransition
        // if (getState().router.previousRoute.name !== 'game') {
        //   history.push(
        //     localeUrl(
        //       getState().router.previousRoute.name,
        //       getState().intl.locale,
        //       {
        //         ...get(getState(), 'router.previousRoute.params'),
        //       },
        //     ),
        //     { noTransition: true },
        //   );
        // } else {
        //   history.push(localeUrl('home', getState().intl.locale), {
        //     noTransition: true,
        //   });
        // }
      });
    });

    document.addEventListener('fw_registrationSucceeded', (...params) => {
      history.push(url('home', { locale: getState().intl.locale }));
      dispatch(
        trackAction('Register success', {
          variation: autoComplete ? 'Autocomplete' : 'Default',
          // ...params,
        }),
      );
    });

    // document.addEventListener('fw_depositSucceeded', (...params) => {
    //   dispatch(
    //     trackAction('Deposit successful', {
    //       variation: 'Default',
    //       currency: getState().wallet.baseCurrency,
    //       wallet: getState().wallet,
    //       //...params,
    //     }),
    //   );
    //
    //   // if(getState().wallet.loyalty.CurrentPoints === 0){
    //   //   dispatch(trackAction('First Deposit', {
    //   //     variation: 'Default',
    //   //     // revenue: _.get(result, 'debit.amount') / getState().wallet.exchangerate,
    //   //     // amount: _.get(result, 'debit.amount'),
    //   //     currency: getState().wallet.baseCurrency,
    //   //     wallet: getState().wallet,
    //   //     //numDeposits: _.size(filtered),
    //   //   }));
    //   // }
    // });

    document.addEventListener('fw_balanceUpdated', () => {
      dispatch(initWallet());
    });

    document.addEventListener('OpenPageFunc', (event) => {
      // return;
      const key = event?.detail?.key;
      console.log('key--key--', key);
      setTimeout(() => {
        document.getElementsByClassName('mwc-close')[0]?.click();
      }, 0);

      switch (key) {
        case '':
          window.AsgFW.showContactUs();

          break;
        // case 'Play':
        //   url = '#';
        //   newWindow = false;
        //   break;

        // case 'BonusPolicy':
        //   url = '/info/bonus-policy/';
        //   break;
        // case 'Bonuses':
        // case 'RewardPoints':
        //   url = '/info/faq/';
        //   break;
        // case 'HelpDeposit':
        //   switch (customInfo) {
        //     case 'bycredit':
        //       url = `${key  }creditcard.aspx`;
        //       break;
        //     default:
        //       url = key + customInfo.replace('-', '') + '.aspx';
        //   }
        //   break;
        case 'email':
          dispatch(openModal('CONTACT_MODAL'));

          break;
        // case 'GamesLobby':
        //   newWindow = false;
        //   url = '/';
        //   break;
        // case 'Redirect':
        //   newWindow = false;
        case 'ResponsibleGaming':
          history.push(localeUrl('responsibleGaming', getState().intl.locale));
          dispatch(closeAllModals());
          break;
        default:
          // if (Object.hasOwnProperty.call(linkMapping, key)) {
          const link = get(linkMapping, key, 'faq');

          history.push(
            localeUrl(
              isObject(link) ? link.route : link,
              getState().intl.locale,
              isObject(link) ? link.params : {},
            ),
          );
          dispatch(closeAllModals());
          // } else {
          if (Object.hasOwnProperty.call(linkMapping, key)) {
            console.log(`unknown info page ${key}`);
          }
        // throw `unknown info page ${key}`;
        // }
      }
    });

    document.addEventListener('ASGSegmentedBannersReady', (data) => {
      dispatch({
        type: 'IS_BANNER_READY',
      });
    });
    document.addEventListener('AspireEngageBannersData', (data) => {
      dispatch({
        type: 'IS_BANNER_READY',
      });
    });
  };
}
