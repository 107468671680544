import { useEffect, useMemo, useState } from 'react';

export function convertCurrency(amount, to) {
  switch (to) {
    case 'SEK':
      return amount * 10;
    case 'NOK':
      return amount * 10;
    default:
      return amount;
  }
}

export function unslugify(slug) {
  if (slug) {
    const result = slug?.replace(/\-/g, ' ');
    return result?.replace?.(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } else {
    return slug;
  }
}
export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function scrollTo(target, duration, params) {
  try {
    const element =
      document.querySelectorAll(params.scrollTo.y) ||
      document.querySelectorAll(params.scrollTo.x) ||
      document.querySelectorAll(params.scrollTo);

    if (element.length === 0) {
      return;
    }

    const offsetElement = document.getElementById('topbar');
    const offset =
      (offsetElement && offsetElement.getBoundingClientRect().height) || 0;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element[0].getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  } catch (e) {
    console.log('scrollTo error', e);
  }
}

export function toQueryParams(object) {
  const parameters = [];
  for (const property in object) {
    if (object.hasOwnProperty(property)) {
      parameters.push(encodeURI(`${property}=${object[property]}`));
    }
  }

  return parameters.join('&');
}

export function parseQueryString(searchParams) {
  // Use location.search to access query string instead
  const qsarr = searchParams.split('?');
  const items = qsarr[qsarr.length > 1 ? 1 : 0].split('&');

  // Consider using reduce to create the data mapping
  return items.reduce((data, item) => {
    const [key, value] = item.split('=');

    // Sometimes a query string can have multiple values
    // for the same key, so to factor that case in, you
    // could collect an array of values for the same key
    if (data[key] !== undefined) {
      // If the value for this key was not previously an
      // array, update it
      if (!Array.isArray(data[key])) {
        data[key] = [data[key]];
      }

      data[key].push(value);
    } else {
      data[key] = value;
    }

    return data;
  }, {});
}

export const get = (object, path, value) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets
  const pathArray = Array.isArray(path)
    ? path
    : path.split(/[,[\].]/g).filter(Boolean);
  // Find value if exist return otherwise return undefined value;
  return (
    pathArray.reduce((prevObj, key) => prevObj && prevObj[key], object) || value
  );
};

export const isFunction = (x) => {
  return typeof x === 'function';
};

export const isObject = (x) => {
  return typeof x === 'object';
};

export const template = (str, params, startdel = '${', enddel = '}') => {
  let urlResult = str;
  for (const key in params) {
    urlResult = str.replace(startdel + key + enddel, params[key]);
  }
  return urlResult;
};

function debounce(func, delay) {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, delay);
  };
}

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    return 0;
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const debouncedResize = debounce(handleResize, 200);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', debouncedResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', debouncedResize);
      }
    };
  }, []);

  const memoizedWindowWidth = useMemo(() => windowWidth, [windowWidth]);

  return memoizedWindowWidth;
};
