/* eslint-disable import/prefer-default-export */

import _ from 'lodash';
import slugify from 'slugify';
import { cache } from '../core/cache';
import { getGameList } from '../data/model/game';
import { getSubCategoryMem } from '../data/model/game-category';

import gamebrowserQuery from './gamebrowser.graphql';
import jackpotsQuery from './jackpots.graphql';

// NEW //
export function setAspireGameData(categories, games) {
  return async (dispatch) => {
    const gamesList = [];

    // if (result) {
    //   Object.keys(jackpots).forEach((key) => {
    //     total += result?.[key]?.amount?.EUR;
    //   });
    games?.instant?.forEach((e) => {
      e.images.default = e.images.default.replace('_1x_', '_3x_');
      if (
        e.name.toLowerCase().indexOf('roulette') === -1 &&
        e.name.toLowerCase().indexOf('blackjack') === -1 &&
        e.name.toLowerCase().indexOf('baccarat') === -1
      ) {
        gamesList[e.id] = e;
      }
    });
    return dispatch({
      type: 'SET_ASPIRE_GAMEDATA',
      categories,
      games: gamesList,
    });
  };
}

export function setGameCategory(category, subCategory) {
  return async (dispatch, getState) => {
    // const gamesList = [];
    // games.instant.map((e) => {
    //   e.images.default = e.images.default.replace('_1x_', '_3x_');
    //   gamesList[e.id] = e;
    // });
    //

    if (category !== '') {
      const { categories } = getState().gamebrowser.gameData;

      if (categories) {
        const foundCategory = categories.mainCategories.find(
          (item) =>
            slugify(item.name, {
              replacement: '-',
              lower: true,
            }) === category,
        );

        const foundSubCategory = foundCategory?.subCategories.find(
          (item) =>
            slugify(item.name, {
              replacement: '-',
              lower: true,
            }) === subCategory,
        );

        if (foundCategory && foundSubCategory) {
          return dispatch({
            type: 'SET_SELECTED_GAMES',
            games: foundSubCategory.instantGamesOrder,
            selectedCategory: category,
            selectedSubCategory: subCategory,
          });
        }
      }
    } else {
      return dispatch({
        type: 'SET_SELECTED_GAMES',
        games: [],
        selectedCategory: '',
        selectedSubCategory: '',
      });
    }
  };
}
// OLD //
export function itemsHasErrored(bool) {
  return { type: 'ITEMS_HAS_ERRORED', hasErrored: bool };
}

export function itemsIsLoading(bool) {
  return { type: 'GAMEBROWSER_SET_LOADING_STATUS', isLoading: bool };
}

export function itemsFetchDataSuccess(items) {
  return { type: 'ITEMS_FETCH_DATA_SUCCESS', items };
}

export function gamebrowserSetCategory(category) {
  return { type: 'GAMEBROWSER_SET_CATEGORY', category };
}

export function removeVariance(variance) {
  return async (dispatch) =>
    dispatch({ type: 'GAMEBROWSER_SET_FILTER_REMOVE_VARIANCE', variance });
}

export function addVariance(variance) {
  return async (dispatch) =>
    dispatch({ type: 'GAMEBROWSER_SET_FILTER_ADD_VARIANCE', variance });
}

export function setEligableForBonus(eligableForBonus) {
  return async (dispatch) =>
    dispatch({ type: 'GAMEBROWSER_SET_FILTER', filter: { eligableForBonus } });
}

export function getLiveLobby() {
  return async (dispatch, getState) => {};
}

export function getGameVendors(games) {
  return async (dispatch, getState) => {
    console.log('getGameVendors', games);

    const vendors = {};
    games?.instant?.map((obj) => {
      if (!vendors[obj.externalGameSubProvider || obj.externalGameProvider]) {
        vendors[obj.externalGameSubProvider || obj.externalGameProvider] = 1;
      } else {
        vendors[obj.externalGameSubProvider || obj.externalGameProvider] += 1;
      }
    });
    console.log('getGameVendors', vendors);
    const uniqueVendors = [
      ...new Set(
        games?.instant?.map(
          (obj) => obj.externalGameSubProvider || obj.externalGameProvider,
        ),
      ),
    ];

    console.log('uniqueVendors', uniqueVendors);
    dispatch({
      type: 'SET_GAMEVENDORS',
      //vendors: _.sortBy(uniqueVendors, (Provider) => Provider.toLowerCase()),
      vendors,
    });
  };
}

export function getLiveVendors() {
  return async (dispatch) =>
    dispatch(getLiveLobby()).then((result) => {
      const uniqueVendors = [
        ...new Set(result.tables.map((obj) => obj.vendor)),
      ];
      dispatch({
        type: 'SET_LIVEVENDORS',
        liveVendors: _.sortBy(uniqueVendors, (vendor) => vendor.toLowerCase()),
      });
    });
}

export function getGames() {
  return async (dispatch, getState) => {
    const games = await getGameList(
      getState().device.isMobile,
      getState().user.IpCountry,
    );

    // if (!getState().auth.isAuthenticated && getState().license.type === 'UK')
    //   games = _.filter(games, { appealingForChildren: "0" });

    if (process.env.BROWSER) dispatch({ type: 'GET_GAMES', games });
    return games;
  };
}

export function getLiveGames() {
  return async (dispatch, getState, { client }) => {
    return client
      .query({
        query: gamebrowserQuery,
        variables: {
          Platform: getState().device.isMobile ? 'Mobile' : 'Desktop',
        },
      })
      .then((result) => {
        dispatch({ type: 'GET_LIVE_GAMES', games: result.data.live });
        return result.data.live;
      });
  };
}

export function getJackpots(currency = 'GBP') {
  return async (dispatch, getState, { client }) => {
    return client
      .query({
        query: jackpotsQuery,
        variables: {
          currency,
        },
      })
      .then((result) => {
        return result.data.jackpots;
      });
  };
}

export function getLastPlayed() {
  return async (dispatch, getState) =>
    new Promise(async (resolve) => {
      if (!getState().auth.isAuthenticated) {
        return resolve([]);
      }

      let lastGames = {};
      const parsed = {};
      try {
        lastGames = await cache.getItem('LAST_PLAYED_GAMES');
        // parsed = JSON.parse(lastGames);
      } catch (e) {
        //
      }
      try {
        if (lastGames) {
          const gameIds = _.get(
            JSON.parse(lastGames),
            getState().device.isMobile ? 'M' : 'D',
            [],
          );
          dispatch(getGames()).then((gamesResult) => {
            const gameList = _.at(_.keyBy(gamesResult, 'id'), gameIds);
            return resolve(gameList);
          });
        } else {
          return resolve([]);
        }
      } catch (e) {
        return resolve([]);
      }
    });
}

// @todo, optimize the loops in here
export function loadLiveCategory() {
  return async (dispatch, getState) => {
    const category = getState().gamebrowser.selectedCategory;
    const fieldName = getState().gamebrowser.orderBy;
    const defaultNumRows = category === 'FEATURED' ? 3 : 4;
    let itemsPerPage;
    let categoryId;

    switch (true) {
      case getState().browser.greaterThan.large:
        itemsPerPage = 6 * defaultNumRows;
        break;
      case getState().browser.greaterThan.medium:
        itemsPerPage = 5 * defaultNumRows;
        break;
      case getState().browser.greaterThan.small:
        itemsPerPage = 4 * defaultNumRows;
        break;
      case getState().browser.greaterThan.extraSmall:
        itemsPerPage = 4 * defaultNumRows;
        break;

      default:
        itemsPerPage = 3 * defaultNumRows;
    }

    switch (getState().user.IpCountry) {
      case 'GB':
        categoryId = 'UK';
        break;
      case 'DE':
      case 'FI':
      case 'NO':
      case 'SE':
        categoryId = getState().user.IpCountry.toLowerCase();
        break;

      default:
        categoryId = 'default';
    }

    dispatch(itemsIsLoading(true));

    switch (category) {
      case 'ALL':
      default:
        dispatch({
          type: 'GAMEBROWSER_SHOW_ALL',
          showAll: true,
        });

        let allResults = [];
        console.log(
          'getState().gamebrowser.selectedCategory',
          getState().gamebrowser.selectedCategory,
        );
        const games = await getSubCategoryMem(
          'LIVECASINO',
          'ALL',
          getState().device.isMobile,
          getState().user.IpCountry,
        );
        if (games) {
          allResults = games.instantGamesOrder;
        }

        console.log('allResults', allResults);
        // dispatch(getLiveLobby()).then(items => {
        const selectedVendors = getState().gamebrowser.filter.vendors;

        // const filter =
        //   (category && category !== 'ALL') || _.size(selectedVendors) > 0
        //     ? o =>
        //         (_.size(getState().gamebrowser.filter.vendors) > 0 &&
        //           (_.indexOf(selectedVendors, o.originalVendor) > -1 ||
        //             _.indexOf(selectedVendors, o.vendor) > -1)) ||
        //         (category && o.category === category)
        //     : {};
        // let allResults = _.filter(items.tables, filter);
        // let allResults = items.tables;
        // allResults = _.orderBy(
        //   allResults,
        //   game =>
        //     typeof game[fieldName] === 'string'
        //       ? game[fieldName].toLowerCase()
        //       : game[fieldName],
        //   getState().gamebrowser.sortOrder,
        // );

        dispatch({
          type: 'GAMEBROWSER_SET_FILTER_COUNT',
          count: _.size(allResults),
        });

        dispatch(itemsFetchDataSuccess(allResults));
        dispatch(itemsIsLoading(false));
        // });

        break;
    }
  };
}

export function loadLiveLobby() {
  return async (dispatch) => {
    dispatch(itemsIsLoading(true));
    dispatch({
      type: 'GAMEBROWSER_SHOW_ALL',
      showAll: true,
    });

    return dispatch(getLiveLobby()).then((items) => {
      console.log('LIVE items', items);
      dispatch(itemsFetchDataSuccess());
      dispatch(itemsIsLoading(false));
    });
  };
}

export function resetFilter() {
  return async (dispatch) => {
    dispatch({
      type: 'GAMEBROWSER_SET_FILTER',
      filter: {
        count: 0,
        vendors: [],
        themes: [],
        eligableForBonus: false,
        variance: [],
      },
    });
  };
}

export function applyFilter(allResults) {
  return async (dispatch, getState) => {
    const variance = {
      low: { minHitFrequency: 35, maxHitFrequency: 1000 },
      medium: { minHitFrequency: 20, maxHitFrequency: 35 },
      high: { minHitFrequency: 0, maxHitFrequency: 20 },
    };
    const selectedVariance = getState().gamebrowser.filter.variance;
    const selectedThemes = getState().gamebrowser.filter.themes;
    const selectedVendors = getState().gamebrowser.filter.vendors;
    const { activeBonuses } = getState().bonus;
    const { eligableForBonus } = getState().gamebrowser.filter;

    const maxHitFrequency = [];
    const minHitFrequency = [];

    if (selectedVariance.length > 0) {
      Object.keys(selectedVariance).forEach((key) => {
        minHitFrequency.push(variance[selectedVariance[key]].minHitFrequency);
        maxHitFrequency.push(variance[selectedVariance[key]].maxHitFrequency);
      });
    }

    const bonusFilter = (o) =>
      eligableForBonus && _.size(activeBonuses) > 0
        ? !_.has(activeBonuses[0].excluded[o.vendor], o.vendorGameID)
        : true;
    const varianceFilter = (o) =>
      _.size(selectedVariance) > 0
        ? o.minHitFrequency >= Math.min(...minHitFrequency) &&
          o.maxHitFrequency <= Math.max(...maxHitFrequency)
        : true;
    const vendorFilter = (o) =>
      _.size(selectedVendors) > 0
        ? _.indexOf(selectedVendors, o.externalGameSubProvider.toLowerCase()) >
            -1 ||
          _.indexOf(selectedVendors, o.externalGameProvider.toLowerCase()) > -1
        : true;

    return _.filter(
      allResults,
      (item) => bonusFilter(item) && varianceFilter(item) && vendorFilter(item),
    );
  };
}

// @todo, optimize the loops in here
export function fetchData() {
  return async (dispatch, getState) => {
    const category = getState().gamebrowser.selectedCategory;
    const defaultNumRows = category === 'FEATURED' ? 3 : 4;
    const fieldName = getState().gamebrowser.orderBy;
    const selectedVendors = getState().gamebrowser.filter.vendors;

    let itemsPerPage;
    switch (true) {
      case getState().browser.greaterThan.large:
        itemsPerPage = 6 * defaultNumRows;
        break;
      case getState().browser.greaterThan.medium:
        itemsPerPage = 5 * defaultNumRows;
        break;
      case getState().browser.greaterThan.small:
        itemsPerPage = 4 * defaultNumRows;
        break;
      case getState().browser.greaterThan.extraSmall:
        itemsPerPage = 4 * defaultNumRows;
        break;

      default:
        itemsPerPage = 3 * defaultNumRows;
    }

    const selectedCategories = [];
    dispatch(itemsIsLoading(true));
    console.log('MY SELECTED CATEGORY', category);
    switch (category) {
      // case 'JACKPOTGAMES':
      //   dispatch(getGames()).then((result) => {
      //     const allResults = _.filter(
      //       result,
      //       ({ JackpotPercentageCont }) => JackpotPercentageCont > 0,
      //     );
      //
      //     dispatch({
      //       type: 'GAMEBROWSER_SHOW_ALL',
      //       showAll:
      //         getState().gamebrowser.pageIndex * itemsPerPage >=
      //         allResults.length,
      //     });
      //
      //     dispatch({
      //       type: 'GAMEBROWSER_SET_FILTER_COUNT',
      //       count: _.size(allResults),
      //     });
      //
      //     dispatch(itemsIsLoading(false));
      //     return dispatch(itemsFetchDataSuccess(allResults));
      //   });
      //   break;

      case 'LIVELOBBY':
        dispatch(loadLiveCategory());
        break;

      default:
        let allResults = [];
        if (category !== 'ALL') {
          selectedCategories.push(getState().gamebrowser.selectedCategory);
        }

        allResults = await getSubCategoryMem(
          getState().router.activeRoute.params.lobbyType === 'general' &&
            _.indexOf(selectedVendors, 'evolution') === -1
            ? 'CASINO'
            : 'LIVECASINO',
          getState().gamebrowser.selectedCategory,
          getState().device.isMobile,
          getState().user.IpCountry,
        );

        console.log(
          'allResults',
          getState().router.activeRoute.params.lobbyType === 'general' &&
            _.indexOf(selectedVendors, 'evolution') === -1
            ? 'CASINO'
            : 'LIVECASINO',
        );
        console.log('allResults', allResults);
        console.log(
          'getState().gamebrowser.selectedCategory',
          getState().gamebrowser.selectedCategory,
        );
        allResults &&
          dispatch(applyFilter(allResults.instantGamesOrder))
            .then((allResults) => {
              allResults = _.orderBy(
                allResults,
                (game) =>
                  typeof game[fieldName] === 'string'
                    ? game[fieldName].toLowerCase()
                    : game[fieldName],
                getState().gamebrowser.sortOrder,
              );

              dispatch({
                type: 'GAMEBROWSER_SET_FILTER_COUNT',
                count: _.size(allResults),
              });

              dispatch({
                type: 'GAMEBROWSER_SHOW_ALL',
                showAll:
                  getState().gamebrowser.pageIndex * itemsPerPage >=
                  allResults.length,
              });
              dispatch(itemsIsLoading(false));
              return dispatch(itemsFetchDataSuccess(allResults));
            })
            .catch((err) => {
              dispatch(itemsIsLoading(false));
            });
    }

    return null;
  };
}

export function startLivePush() {
  return async (dispatch, getState) => {
    if (process.env.BROWSER) {
    }
  };
}
const cat = null;

export function preloadCategories(forceRecheck = false) {
  return async (dispatch) => {
    // if (process.env.BROWSER) {
    //   cache.clearAll('xpMissions');
    //   cache.clearAll('xpCollections');
    //   if (cat && !forceRecheck) {
    //     return cat;
    //   }
    //   cat = Promise.all([
    //     dispatch(getGames()),
    // dispatch(getGameVendors())
    //     dispatch(getLastPlayed()),
    //     dispatch(getLiveLobby()),
    //     dispatch(getLiveVendors()),
    //     dispatch(getCustomCategories()),
    //   ]);
    //   return cat;
    // }
  };
}

export function showMore() {
  return (dispatch, getState) => {
    let itemsPerPage;
    switch (true) {
      case getState().browser.greaterThan.large:
        itemsPerPage = 6 * 4;
        break;
      case getState().browser.greaterThan.medium:
        itemsPerPage = 5 * 4;
        break;
      case getState().browser.greaterThan.small:
        itemsPerPage = 4 * 4;
        break;
      case getState().browser.greaterThan.extraSmall:
        itemsPerPage = 4 * 4;
        break;
      default:
        itemsPerPage = 3 * 4;
    }

    dispatch({
      type: 'GAMEBROWSER_SHOW_MORE',
      pageIndex: getState().gamebrowser.pageIndex + 1,
      showNumItems: itemsPerPage * (getState().gamebrowser.pageIndex + 1),
    });

    dispatch(fetchData());
  };
}

export function showAll() {
  return (dispatch) => {
    dispatch({
      type: 'GAMEBROWSER_SHOW_ALL',
      showAll: true,
    });
    dispatch(fetchData());
  };
}

export function setCategory(category, reload = true) {
  return (dispatch, getState) =>
    new Promise((resolve) => {
      let itemsPerPage;

      switch (true) {
        case getState().browser.greaterThan.large:
          itemsPerPage = 6 * 4;
          break;
        case getState().browser.greaterThan.medium:
          itemsPerPage = 5 * 4;
          break;
        case getState().browser.greaterThan.small:
          itemsPerPage = 4 * 4;
          break;
        case getState().browser.greaterThan.extraSmall:
        default:
          itemsPerPage = 4 * 4;
          break;
      }
      dispatch({
        type: 'GAMEBROWSER_SHOW_MORE',
        pageIndex: 1,
        showNumItems: itemsPerPage * getState().gamebrowser.pageIndex,
      });

      dispatch({ type: 'GAMEBROWSER_SHOW_ALL', showAll: false });

      dispatch(gamebrowserSetCategory(category));

      if (reload) {
        dispatch(fetchData());
      }
      resolve();
    });
}

export function setSortOrder(direction) {
  return async (dispatch) =>
    dispatch({ type: 'SET_SORT_ORDER', sortOrder: direction });
}

export function setOrderBy(orderBy) {
  return async (dispatch) => dispatch({ type: 'SET_ORDER_BY', orderBy });
}

export function setVendor(vendor) {
  return async (dispatch, getState) => {
    dispatch({ type: 'GAMEBROWSER_SET_FILTER', filter: { vendors: [vendor] } });

    const { games } = getState().gamebrowser.gameData;

    console.log('games', games);
    console.log('vendor', vendor);
    const filteredGames = [];
    games.map((game) => {
      if (
        game?.externalGameProvider === vendor ||
        game?.externalGameSubProvider === vendor
      ) {
        filteredGames.push(game.id);
      }
    });
    return dispatch({
      type: 'SET_SELECTED_GAMES',
      games: filteredGames,
      selectedCategory: 'casino',
      selectedSubCategory: 'all-games',
    });
  };
}

export function setThemes(themes) {
  return async (dispatch) =>
    dispatch({ type: 'GAMEBROWSER_SET_FILTER', filter: { themes } });
}

export function setVendors(vendors) {
  return async (dispatch) => {
    dispatch({ type: 'GAMEBROWSER_SHOW_ALL', showAll: false });
    dispatch({ type: 'GAMEBROWSER_SET_FILTER', filter: { vendors } });
  };
}

export function searchLiveGames(query) {
  return async (dispatch, getState) => {
    dispatch(itemsIsLoading(true));
    const result = await getSubCategoryMem(
      'LIVECASINO',
      'ALL',
      getState().device.isMobile,
      getState().user.IpCountry,
    );

    // dispatch(getLiveLobby()).then(result => {
    const searchResultSearch =
      _.size(query) >= 2
        ? _.filter(
            result.instantGamesOrder,
            _.method('name.match', new RegExp(query, 'i')),
          )
        : [];
    const searchResultVendor = _.filter(
      result.instantGamesOrder,
      _.method('externalGameProvider.match', new RegExp(query, 'i')),
    );
    const searchResult = _.concat(searchResultSearch, searchResultVendor);

    dispatch({
      type: 'GAMEBROWSER_SHOW_ALL',
      showAll: true,
    });

    dispatch({
      type: 'GAMEBROWSER_SET_FILTER_COUNT',
      count: _.size(searchResult),
    });

    dispatch(itemsFetchDataSuccess(searchResult));
    dispatch(itemsIsLoading(false));
    // });
  };
}

export function searchGames(query) {
  return async (dispatch, getState) => {
    dispatch(itemsIsLoading(true));

    const result = await getGameList(
      getState().device.isMobile,
      getState().user.IpCountry,
    );
    // const searchResultSearch = _.filter(
    //   result,
    //   _.method('name.match', new RegExp(query, 'i')),
    // );
    const regexForSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
    const searchResultSearch = result?.filter((game) =>
      game?.name
        ?.trim()
        .replaceAll(regexForSpecialChar, '')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        ?.includes(
          query
            ?.trim()
            .replaceAll(regexForSpecialChar, '')
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        ),
    );
    const searchResultVendor = _.filter(
      result,
      _.method('Provider.match', new RegExp(query, 'i')),
    );
    const searchResult = _.concat(searchResultSearch, searchResultVendor);

    dispatch(itemsFetchDataSuccess(searchResult));
    dispatch({
      type: 'GAMEBROWSER_SET_FILTER_COUNT',
      count: _.size(searchResult),
    });
    dispatch({
      type: 'GAMEBROWSER_SHOW_ALL',
      showAll: true,
    });

    dispatch(itemsIsLoading(false));
  };
}

export const setToTopActive = (isActive) => (dispatch, getState) => {
  dispatch({
    type: 'GAMEBROWSER_TO_TOP_IS_ACTIVE',
    isGoToTopActive: isActive,
  });
};
