export default function(state= {}, action) {
  switch (action.type) {
    case 'SET_CACHE':
      return {
        ...state,
        ...{ [action.payload.key]: action.payload.cacheData },
      };

    default:
      return state;
  }
}
