import {openModal} from './modal';

export function continueToDeposit(config) {
  return (dispatch, getState, { AutobahnReact, gamebrowser }) => {
    dispatch(openModal('DEPOSIT_MODAL', config));
  };
}

export function startDeposit(config) {
  return (dispatch, getState, { AutobahnReact, gamebrowser }) => {
    dispatch(continueToDeposit(config));
  };
}
