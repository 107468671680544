import React from 'react';
import {getWallet} from '../data/model/wallet';
import currencyRates from '../../public/feeds/currencies.json';

// @todo, culture dynamic
export function initWallet() {
  return dispatch => {
    dispatch({
      type: 'SET_WALLET_LOADING_STATUS',
      payload: { isLoading: true },
    });

    let wallet = getWallet();
    dispatch({
      type: 'INIT_WALLET',
      payload: {
        isLoading: false,
        ...wallet,
      },
    });

    wallet = window.AsgFW.getDisplayBalance();
    dispatch(setDefaultCurrency(wallet.Currency));
    window.baseCurrency = wallet.Currency;
    window.currencyRate = Object.prototype.hasOwnProperty.call(
      currencyRates.rates,
      wallet.Currency,
    )
      ? currencyRates.rates[wallet.Currency]
      : 1.0;
  };
}

export function setExchangeRate(rate) {
  return (dispatch, getState, { AutobahnReact }) => {
    dispatch({
      type: 'UPDATE_EXCHANGERATE',
      payload: { exchangerate: rate },
    });
  };
}

export function setDefaultCurrency(currency) {
  return (dispatch, getState, { AutobahnReact }) => {
    const symbols = {
      EUR: '€',
      GBP: '£',
      USD: '$',
      SEK: 'kr',
      CAD: '$',
      NOK: 'kr',
      AUD: '$',
      INR: '₹',
      NZD: '$',
    };
    // currency = symbols.hasOwnProperty(currency) ? currency : 'GBP';
    // CAD NOK AUD SEK
    // Only update if we have a currency and they are not the same
    if (currency && getState().wallet.baseCurrency !== currency) {
      dispatch({
        type: 'UPDATE_CURRENCY',
        payload: {
          baseCurrency: currency,
          currencySymbol: symbols[currency],
        },
      });
    }
  };
}
