export default function message(
  state = { messages: [], activeMessage: {}, isActive: false },
  action,
) {
  switch (action.type) {
    case 'INIT_MESSAGE_CENTER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SHOW_MESSAGE_CENTER':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
