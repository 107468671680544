import {trackAction} from "./analytics";
import {checkIfFrameworkisLoaded} from '../core/aspire';


export function openModal(modalName, modalProps = {}) {
  return async (dispatch, getState, { client }) => {


    return checkIfFrameworkisLoaded(() => {
      switch (modalName) {
        case 'REGISTER_MODAL':
          return window.AsgFW.showRegistration();
          break;
        case 'AUTH_MODAL':

          // console.log('modal');
          // var myModule = angular.module('myModule', []);
          //
          // myModule.config(["$provide", $provide => {
          //   $provide.decorator("$browser", ["$delegate", $delegate => {
          //
          //     console.log('configuring modal', $delegate)
          //     $delegate.onUrlChange = () => { };
          //     $delegate.url = () => "";
          //
          //     return $delegate;
          //   }]);
          // }]);
          //
          // var $injector = window.angular.injector(['ng', 'myModule']);
          // $injector.invoke(function($rootScope, $compile, $document) {
          //   $compile($document)($rootScope);
          //   $rootScope.$digest();
          // });

          dispatch(
            trackAction('Login modal open', {
              variation: 'Default',
            }),
          );
          return window.AsgFW.showLogin();
          break;
        case 'DEPOSIT_MODAL':

          // const seperateFunds = window.localStorage.getItem('SEPERATE_FUNDS');
          // if (getState().license.type === 'UK' && !seperateFunds) {
          //   // client
          //   //   .query({
          //   //     query: getOptInDepositQuery,
          //   //     fetchPolicy: 'network-only',
          //   //     variables: { playerID: getState().user.PlayerID },
          //   //   })
          //   //   .then(response => {
          //   //     if (!response.data.result) {
          //
          //         dispatch(
          //           trackAction('Seperate funds modal open', {
          //             variation: 'Default',
          //           }),
          //         );
          //         dispatch(
          //           openModal('SEPARATE_FUNDS_MODAL', {
          //             onAgree: () => {
          //               client
          //                 .mutate({
          //                   mutation: setOptInDepositQuery,
          //                   fetchPolicy: 'no-cache',
          //                   variables: {
          //                     playerID: getState().user.PlayerID,
          //                     brandID: '279',
          //                   },
          //                 });
          //                 window.localStorage.setItem('SEPERATE_FUNDS', true);
          //                 // .then(response => {
          //                 //   dispatch(closeModal('SEPARATE_FUNDS_MODAL'));
          //                 //   window.AsgFW.showDeposit();
          //                 // });
          //               dispatch(closeModal('SEPARATE_FUNDS_MODAL'));
          //
          //               dispatch(
          //                 trackAction('Deposit modal open', {
          //                   variation: 'Default',
          //                   fromSeperateFunds: true
          //                 }),
          //               );
          //               window.AsgFW.showDeposit();
          //             },
          //           }),
          //         );
          //       // } else {
          //       //   window.AsgFW.showDeposit();
          //       // }
          //     // });
          // } else {

            dispatch(
              trackAction('Deposit modal open', {
                variation: 'Default',
                fromSeperateFunds: false
              }),
            );

            window.AsgFW.showDeposit();
          // }

          break;
        case 'WITHDRAW_MODAL':
          window.AsgFW.showWithdraw();
          break;
        case 'ACCOUNT_MODAL':
          window.AsgFW.showMyAccount();
          break;
        case 'TRANSACTON_HISTORY_MODAL':
          window.AsgFW.showTransactionsHistory();
          break;
        case 'DEPOSITLIMIT_MODAL':
          window.AsgFW.showDepositLimit();
          break;
        case 'REALITYCHECK_MODAL':
          window.AsgFW.showRealityCheck();
          break;
        case 'SELF_EXCLUSION_MODAL':
          window.AsgFW.showSelfExclusion();
          break;
        case 'REWARD_BROWSER_MODAL':
        case 'DASHBOARD_REWARD_MODAL':
          window.ng_fw.showBonusOffers();
          break;

        default:
          // check if we don't have one in the state already
          if (!getState().modal.find(o => o.modalName === modalName)) {
            return dispatch({
              type: 'SHOW_MODAL',
              payload: { modalName, modalProps },
            });
          }
      }
    });
  };

}

export function closeModal(modalName) {
  // document.body.classList.toggle('modalIsActive', false);
  return async (dispatch, getState, { AutobahnReact }) => {
    return dispatch({
      type: 'HIDE_MODAL',
      payload: { modalName },
    });
  };
}

export function closeAllModals() {
  return async (dispatch, getState, { AutobahnReact }) => {
    return dispatch({
      type: 'HIDE_ALL_MODALS',
    });
  };
}
