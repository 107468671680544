export default function wallet(
  state = {
    isLoading: true,
    total: 0,
    baseCurrency: 'GBP',
    currencySymbol: '£',
    isWatchBalanceSubscribed: false,
  },
  action,
) {
  switch (action.type) {
    case 'UPDATE_EXCHANGERATE':
    case 'UPDATE_CURRENCY':
    case 'UPDATE_WALLET':
    case 'INIT_WALLET':
    case 'SET_WALLET_LOADING_STATUS':
    case 'SET_WALLET_LISTENER_SUBSCRIBED':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
