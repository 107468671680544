import {INIT_USER_SESSION} from '../constants/Auth';

export default function user(
  state = { canRegister: false, registrationIsAllowedStatus: 'init', alternatives:[] },
  action,
) {
  switch (action.type) {
    case INIT_USER_SESSION:
      return {
        ...state,
        ...action.payload,
      };

    case 'REGISTRATION_IS_ALLOWED':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function itemsHasErrored(state = false, action) {
  switch (action.type) {
    case 'ITEMS_HAS_ERRORED':
      return action.hasErrored;
    default:
      return state;
  }
}

export function itemsIsLoading(state = false, action) {
  switch (action.type) {
    case 'ITEMS_IS_LOADING':
      return action.isLoading;
    default:
      return state;
  }
}

export function items(state = [], action) {
  switch (action.type) {
    case 'ITEMS_FETCH_DATA_SUCCESS':
      return action.items;
    default:
      return state;
  }
}
