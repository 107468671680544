/* eslint-disable import/prefer-default-export */
export function setOnSessionStatusUpdate(loggedInCallback, loggedOutCallback) {
  window.AsgFW.setOnSessionStatusUpdate(() => {
    if (window.AsgFW.isLoggedIn()) {
      loggedInCallback();
    } else {
      loggedOutCallback();
    }
  });
}

export function getSessionDetails() {
  return window.AsgFW.getSessionDetails();
}

export function logout() {
  return window.AsgFW.logout();
}
