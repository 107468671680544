export function app(state = { isLoading: true }, action) {
  switch (action.type) {
    case 'APP_INITIALIZE_IN_PROGRESS':
      return {
        ...state,
        ...action.payload,
      };
    case 'APP_INITIALIZE_DONE':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
